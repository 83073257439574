module.exports = [
	{
		"value": "濮阳",
		"label": "濮阳",
		"children": [
			{
				"label": "范县",
				"value": "范县",
				"children": [
					{
						"label": "中国乒协（濮阳）乒乓球实验学校",
						"value": "中国乒协（濮阳）乒乓球实验学校"
					},
					{
						"label": "范县第一中学",
						"value": "范县第一中学"
					},
					{
						"label": "范县希望中学",
						"value": "范县希望中学"
					},
					{
						"label": "范县卓越中学",
						"value": "范县卓越中学"
					}
				]
			},
			{
				"label": "华龙区",
				"value": "华龙区",
				"children": [
					{
						"label": "濮阳油田实验学校",
						"value": "濮阳油田实验学校"
					},
					{
						"label": "濮阳创新高级中学",
						"value": "濮阳创新高级中学"
					},
					{
						"label": "濮阳县建业国际学校",
						"value": "濮阳县建业国际学校"
					},
					{
						"label": "濮阳市华龙区高级中学",
						"value": "濮阳市华龙区高级中学"
					},
					{
						"label": "濮阳龙乡中学",
						"value": "濮阳龙乡中学"
					},
					{
						"label": "濮阳油田梦海实验学校",
						"value": "濮阳油田梦海实验学校"
					},
					{
						"label": "濮阳乐成学校",
						"value": "濮阳乐成学校"
					},
					{
						"label": "濮阳兴龙学校",
						"value": "濮阳兴龙学校"
					},
					{
						"label": "濮阳市东方外语学校",
						"value": "濮阳市东方外语学校"
					},
					{
						"label": "濮阳市第二高级中学",
						"value": "濮阳市第二高级中学"
					},
					{
						"label": "濮阳市第一高级中学",
						"value": "濮阳市第一高级中学"
					},
					{
						"label": "濮阳外国语学校",
						"value": "濮阳外国语学校"
					},
					{
						"label": "濮阳市油田第一中学",
						"value": "濮阳市油田第一中学"
					},
					{
						"label": "濮阳市油田第二高级中学",
						"value": "濮阳市油田第二高级中学"
					},
					{
						"label": "濮阳市油田第三高级中学",
						"value": "濮阳市油田第三高级中学"
					},
					{
						"label": "濮阳市油田第四高级中学",
						"value": "濮阳市油田第四高级中学"
					},
					{
						"label": "濮阳市油田艺术中学",
						"value": "濮阳市油田艺术中学"
					},
					{
						"label": "濮阳市油田基地高级中学",
						"value": "濮阳市油田基地高级中学"
					}
				]
			},
			{
				"label": "台前县",
				"value": "台前县",
				"children": [
					{
						"label": "台前县励志中学",
						"value": "台前县励志中学"
					},
					{
						"label": "台前县第一高级中学",
						"value": "台前县第一高级中学"
					}
				]
			},
			{
				"label": "南乐县",
				"value": "南乐县",
				"children": [
					{
						"label": "濮阳博文学校",
						"value": "濮阳博文学校"
					},
					{
						"label": "南乐县实验高级中学",
						"value": "南乐县实验高级中学"
					},
					{
						"label": "南乐县黄冈中学",
						"value": "南乐县黄冈中学"
					},
					{
						"label": "河南南乐县第一高级中学",
						"value": "河南南乐县第一高级中学"
					}
				]
			},
			{
				"label": "濮阳县",
				"value": "濮阳县",
				"children": [
					{
						"label": "濮阳县第三中学",
						"value": "濮阳县第三中学"
					},
					{
						"label": "濮阳县实验高中",
						"value": "濮阳县实验高中"
					},
					{
						"label": "濮阳县第一中学",
						"value": "濮阳县第一中学"
					},
					{
						"label": "濮阳职业技术学院附属中学",
						"value": "濮阳职业技术学院附属中学"
					},
					{
						"label": "濮阳县第六中学",
						"value": "濮阳县第六中学"
					}
				]
			},
			{
				"label": "清丰县",
				"value": "清丰县",
				"children": [
					{
						"label": "濮阳油田英杰学校",
						"value": "濮阳油田英杰学校"
					},
					{
						"label": "华中师范大学附属清丰高级中学（筹）",
						"value": "华中师范大学附属清丰高级中学（筹）"
					},
					{
						"label": "清丰县第一高级中学",
						"value": "清丰县第一高级中学"
					}
				]
			},
			{
				"label": "龙华区",
				"value": "龙华区",
				"children": [
					{
						"label": "濮阳市元龙高级中学",
						"value": "濮阳市元龙高级中学"
					},
					{
						"label": "濮阳外国语实验高中",
						"value": "濮阳外国语实验高中"
					}
				]
			}
		]
	},
	{
		"value": "商丘",
		"label": "商丘",
		"children": [
			{
				"label": "睢阳区",
				"value": "睢阳区",
				"children": [
					{
						"label": "商丘市睢阳区第一中学",
						"value": "商丘市睢阳区第一中学"
					},
					{
						"label": "商丘市第二中学",
						"value": "商丘市第二中学"
					},
					{
						"label": "商丘市第四高级中学",
						"value": "商丘市第四高级中学"
					},
					{
						"label": "商丘中学",
						"value": "商丘中学"
					},
					{
						"label": "商丘市第二十中学",
						"value": "商丘市第二十中学"
					},
					{
						"label": "商丘市第一高级中学",
						"value": "商丘市第一高级中学"
					},
					{
						"label": "商丘市应天高级中学",
						"value": "商丘市应天高级中学"
					},
					{
						"label": "商丘市第二高级中学",
						"value": "商丘市第二高级中学"
					}
				]
			},
			{
				"label": "梁园区",
				"value": "梁园区",
				"children": [
					{
						"label": "商丘工学院附属兴华学校",
						"value": "商丘工学院附属兴华学校"
					},
					{
						"label": "商丘市第三高级中学",
						"value": "商丘市第三高级中学"
					},
					{
						"label": "商丘市实验中学",
						"value": "商丘市实验中学"
					},
					{
						"label": "商丘市回民中学",
						"value": "商丘市回民中学"
					}
				]
			},
			{
				"label": "夏邑县",
				"value": "夏邑县",
				"children": [
					{
						"label": "夏邑县第一高级中学（夏邑县田家炳高级中学）",
						"value": "夏邑县第一高级中学（夏邑县田家炳高级中学）"
					},
					{
						"label": "夏邑县高级中学",
						"value": "夏邑县高级中学"
					},
					{
						"label": "夏邑县车站高级中学",
						"value": "夏邑县车站高级中学"
					},
					{
						"label": "夏邑县第三高中",
						"value": "夏邑县第三高中"
					},
					{
						"label": "夏邑县会亭高中",
						"value": "夏邑县会亭高中"
					}
				]
			},
			{
				"label": "虞城县",
				"value": "虞城县",
				"children": [
					{
						"label": "虞城县春来高级中学",
						"value": "虞城县春来高级中学"
					},
					{
						"label": "虞城县高级中学",
						"value": "虞城县高级中学"
					},
					{
						"label": "虞城县第二高级中学",
						"value": "虞城县第二高级中学"
					}
				]
			},
			{
				"label": "永城市",
				"value": "永城市",
				"children": [
					{
						"label": "永城市林肯英语环境学校（高中部）",
						"value": "永城市林肯英语环境学校（高中部）"
					},
					{
						"label": "永城市小龙人中学（高中部）",
						"value": "永城市小龙人中学（高中部）"
					},
					{
						"label": "永城市第二高级中学",
						"value": "永城市第二高级中学"
					},
					{
						"label": "永城市第一高级中学",
						"value": "永城市第一高级中学"
					},
					{
						"label": "永城市第三高级中学",
						"value": "永城市第三高级中学"
					},
					{
						"label": "永城职业学院附属中学",
						"value": "永城职业学院附属中学"
					}
				]
			},
			{
				"label": "柘城县",
				"value": "柘城县",
				"children": [
					{
						"label": "柘城县第一高级中学",
						"value": "柘城县第一高级中学"
					},
					{
						"label": "柘城县第二高级中学",
						"value": "柘城县第二高级中学"
					},
					{
						"label": "柘城县第四高级中学",
						"value": "柘城县第四高级中学"
					},
					{
						"label": "柘城县高级中学",
						"value": "柘城县高级中学"
					},
					{
						"label": "柘城县行知高中",
						"value": "柘城县行知高中"
					}
				]
			},
			{
				"label": "睢县",
				"value": "睢县",
				"children": [
					{
						"label": "睢县高级中学",
						"value": "睢县高级中学"
					},
					{
						"label": "睢县回族高级中学",
						"value": "睢县回族高级中学"
					},
					{
						"label": "睢县第三高级中学",
						"value": "睢县第三高级中学"
					}
				]
			},
			{
				"label": "宁陵县",
				"value": "宁陵县",
				"children": [
					{
						"label": "宁陵县高级中学",
						"value": "宁陵县高级中学"
					},
					{
						"label": "宁陵县第二高级中学",
						"value": "宁陵县第二高级中学"
					}
				]
			},
			{
				"label": "民权县",
				"value": "民权县",
				"children": [
					{
						"label": "民权县第一高级中学",
						"value": "民权县第一高级中学"
					},
					{
						"label": "民权县高级中学",
						"value": "民权县高级中学"
					},
					{
						"label": "民权县九九高级中学",
						"value": "民权县九九高级中学"
					}
				]
			}
		]
	},
	{
		"value": "南阳",
		"label": "南阳",
		"children": [
			{
				"label": "镇平县",
				"value": "镇平县",
				"children": [
					{
						"label": "镇平县金陵外国语学校",
						"value": "镇平县金陵外国语学校"
					},
					{
						"label": "镇平实验学校",
						"value": "镇平实验学校"
					},
					{
						"label": "镇平县第一高级中学",
						"value": "镇平县第一高级中学"
					},
					{
						"label": "镇平县雪枫中学",
						"value": "镇平县雪枫中学"
					},
					{
						"label": "镇平县第二高级中学",
						"value": "镇平县第二高级中学"
					}
				]
			},
			{
				"label": "卧龙区",
				"value": "卧龙区",
				"children": [
					{
						"label": "南阳开元国际学校",
						"value": "南阳开元国际学校"
					},
					{
						"label": "南阳市民进学校",
						"value": "南阳市民进学校"
					},
					{
						"label": "南阳市卧龙区博雅学校",
						"value": "南阳市卧龙区博雅学校"
					},
					{
						"label": "南阳市艺术中学",
						"value": "南阳市艺术中学"
					},
					{
						"label": "河南省南阳市油田第二中学",
						"value": "河南省南阳市油田第二中学"
					},
					{
						"label": "南阳市卧龙区豫阳实验学校",
						"value": "南阳市卧龙区豫阳实验学校"
					},
					{
						"label": "南阳市第一完全学校",
						"value": "南阳市第一完全学校"
					},
					{
						"label": "南阳市张衡实验学校",
						"value": "南阳市张衡实验学校"
					},
					{
						"label": "南阳市拔萃中学",
						"value": "南阳市拔萃中学"
					},
					{
						"label": "南阳市第二十九中学校",
						"value": "南阳市第二十九中学校"
					},
					{
						"label": "南阳市南开实验学校",
						"value": "南阳市南开实验学校"
					},
					{
						"label": "南阳市第八中学校",
						"value": "南阳市第八中学校"
					},
					{
						"label": "南阳市第二十一中学校",
						"value": "南阳市第二十一中学校"
					},
					{
						"label": "南阳市第十五中学",
						"value": "南阳市第十五中学"
					},
					{
						"label": "南阳市第十一高级中学",
						"value": "南阳市第十一高级中学"
					}
				]
			},
			{
				"label": "新野县",
				"value": "新野县",
				"children": [
					{
						"label": "新野县明德双语学校",
						"value": "新野县明德双语学校"
					},
					{
						"label": "新野县实验高中",
						"value": "新野县实验高中"
					},
					{
						"label": "新野县育才综合学校",
						"value": "新野县育才综合学校"
					},
					{
						"label": "新野县第二高级中学校",
						"value": "新野县第二高级中学校"
					},
					{
						"label": "新野县第三高级中学",
						"value": "新野县第三高级中学"
					},
					{
						"label": "新野县第一高级中学校",
						"value": "新野县第一高级中学校"
					},
					{
						"label": "新野县文府中学高中部",
						"value": "新野县文府中学高中部"
					}
				]
			},
			{
				"label": "高新区",
				"value": "高新区",
				"children": [
					{
						"label": "南阳市第三完全学校",
						"value": "南阳市第三完全学校"
					}
				]
			},
			{
				"label": "西峡县",
				"value": "西峡县",
				"children": [
					{
						"label": "宛西育才实验学校",
						"value": "宛西育才实验学校"
					},
					{
						"label": "河南省西峡县第一高级中学",
						"value": "河南省西峡县第一高级中学"
					},
					{
						"label": "西峡县第二高级中学",
						"value": "西峡县第二高级中学"
					},
					{
						"label": "西峡县第三高级中学",
						"value": "西峡县第三高级中学"
					}
				]
			},
			{
				"label": "社旗县",
				"value": "社旗县",
				"children": [
					{
						"label": "社旗县新时代国际学校",
						"value": "社旗县新时代国际学校"
					},
					{
						"label": "社旗县第一高级中学",
						"value": "社旗县第一高级中学"
					},
					{
						"label": "社旗县第二高级中学",
						"value": "社旗县第二高级中学"
					},
					{
						"label": "社旗县第三高中",
						"value": "社旗县第三高中"
					},
					{
						"label": "社旗县宛东实验高级中学",
						"value": "社旗县宛东实验高级中学"
					}
				]
			},
			{
				"label": "宛城区",
				"value": "宛城区",
				"children": [
					{
						"label": "南阳市成达中学",
						"value": "南阳市成达中学"
					},
					{
						"label": "河南省南阳市油田第一中学",
						"value": "河南省南阳市油田第一中学"
					},
					{
						"label": "南阳市元培实验高级中学校",
						"value": "南阳市元培实验高级中学校"
					},
					{
						"label": "南阳市第四完全学校高中部",
						"value": "南阳市第四完全学校高中部"
					},
					{
						"label": "南阳高新区豫龙宏志中学",
						"value": "南阳高新区豫龙宏志中学"
					},
					{
						"label": "南阳市第二完全学校高级中学",
						"value": "南阳市第二完全学校高级中学"
					},
					{
						"label": "南阳市兴宛学校",
						"value": "南阳市兴宛学校"
					},
					{
						"label": "南阳市汉冶中学",
						"value": "南阳市汉冶中学"
					},
					{
						"label": "河南省南阳市第十中学校",
						"value": "河南省南阳市第十中学校"
					},
					{
						"label": "南阳市第四中学",
						"value": "南阳市第四中学"
					},
					{
						"label": "南阳市第五中学校",
						"value": "南阳市第五中学校"
					},
					{
						"label": "南阳市第一中学校",
						"value": "南阳市第一中学校"
					},
					{
						"label": "南阳市华龙学校",
						"value": "南阳市华龙学校"
					}
				]
			},
			{
				"label": "桐柏县",
				"value": "桐柏县",
				"children": [
					{
						"label": "河南省南阳市油田第四中学",
						"value": "河南省南阳市油田第四中学"
					},
					{
						"label": "桐柏县实验高中",
						"value": "桐柏县实验高中"
					},
					{
						"label": "桐柏县第一高级中学",
						"value": "桐柏县第一高级中学"
					},
					{
						"label": "桐柏县胜出高级中学",
						"value": "桐柏县胜出高级中学"
					}
				]
			},
			{
				"label": "唐河县",
				"value": "唐河县",
				"children": [
					{
						"label": "唐河县育才实验学校",
						"value": "唐河县育才实验学校"
					},
					{
						"label": "唐河县第一高级中学",
						"value": "唐河县第一高级中学"
					},
					{
						"label": "唐河县第四高级中学",
						"value": "唐河县第四高级中学"
					},
					{
						"label": "唐河县友兰实验高中",
						"value": "唐河县友兰实验高中"
					}
				]
			},
			{
				"label": "南召县",
				"value": "南召县",
				"children": [
					{
						"label": "南阳市鸭河工区南都实验中学",
						"value": "南阳市鸭河工区南都实验中学"
					},
					{
						"label": "南召县第二高级中学",
						"value": "南召县第二高级中学"
					},
					{
						"label": "南召县兴云学校",
						"value": "南召县兴云学校"
					},
					{
						"label": "南召县红阳高级中学",
						"value": "南召县红阳高级中学"
					},
					{
						"label": "南召县第三高级中学",
						"value": "南召县第三高级中学"
					},
					{
						"label": "南召县第一高级中学",
						"value": "南召县第一高级中学"
					},
					{
						"label": "南召现代中学",
						"value": "南召现代中学"
					}
				]
			},
			{
				"label": "邓州市",
				"value": "邓州市",
				"children": [
					{
						"label": "邓州市范仲淹公学",
						"value": "邓州市范仲淹公学"
					},
					{
						"label": "邓州市翰林实验学校",
						"value": "邓州市翰林实验学校"
					},
					{
						"label": "邓州市第一高级中学校",
						"value": "邓州市第一高级中学校"
					},
					{
						"label": "邓州市第二高级中学校",
						"value": "邓州市第二高级中学校"
					},
					{
						"label": "邓州市花洲实验高级中学校",
						"value": "邓州市花洲实验高级中学校"
					},
					{
						"label": "邓州市穰东高级中学校",
						"value": "邓州市穰东高级中学校"
					},
					{
						"label": "邓州市第五高级中学校",
						"value": "邓州市第五高级中学校"
					},
					{
						"label": "邓州市第六高级中学校",
						"value": "邓州市第六高级中学校"
					},
					{
						"label": "邓州市第三高级中学校",
						"value": "邓州市第三高级中学校"
					}
				]
			},
			{
				"label": "内乡县",
				"value": "内乡县",
				"children": [
					{
						"label": "内乡菊潭高级中学",
						"value": "内乡菊潭高级中学"
					},
					{
						"label": "内乡县第三高级中学",
						"value": "内乡县第三高级中学"
					},
					{
						"label": "内乡县高级中学",
						"value": "内乡县高级中学"
					},
					{
						"label": "内乡县灌涨高级中学",
						"value": "内乡县灌涨高级中学"
					},
					{
						"label": "内乡县实验高级中学",
						"value": "内乡县实验高级中学"
					}
				]
			},
			{
				"label": "淅川县",
				"value": "淅川县",
				"children": [
					{
						"label": "淅川县丹阳国际书院",
						"value": "淅川县丹阳国际书院"
					},
					{
						"label": "淅川县第一高级中学",
						"value": "淅川县第一高级中学"
					},
					{
						"label": "淅川县第二高级中学",
						"value": "淅川县第二高级中学"
					},
					{
						"label": "淅川县第五高级中学",
						"value": "淅川县第五高级中学"
					},
					{
						"label": "淅川县荆紫关高级中学",
						"value": "淅川县荆紫关高级中学"
					},
					{
						"label": "淅川县隆兴高中",
						"value": "淅川县隆兴高中"
					}
				]
			},
			{
				"label": "方城县",
				"value": "方城县",
				"children": [
					{
						"label": "方城县第二高级中学",
						"value": "方城县第二高级中学"
					},
					{
						"label": "方城县红星学校",
						"value": "方城县红星学校"
					},
					{
						"label": "方城县国际学校",
						"value": "方城县国际学校"
					},
					{
						"label": "方城县第五高级中学",
						"value": "方城县第五高级中学"
					},
					{
						"label": "方城县第一高级中学",
						"value": "方城县第一高级中学"
					},
					{
						"label": "方城县第一高级中学分校",
						"value": "方城县第一高级中学分校"
					}
				]
			},
			{
				"label": "中州路",
				"value": "中州路",
				"children": [
					{
						"label": "南阳市第二中学校",
						"value": "南阳市第二中学校"
					}
				]
			}
		]
	},
	{
		"value": "周口",
		"label": "周口",
		"children": [
			{
				"label": "淮阳区",
				"value": "淮阳区",
				"children": [
					{
						"label": "淮阳第二高级中学",
						"value": "淮阳第二高级中学"
					},
					{
						"label": "淮阳第一高级中学",
						"value": "淮阳第一高级中学"
					},
					{
						"label": "陈州高级中学",
						"value": "陈州高级中学"
					},
					{
						"label": "淮阳县实验中学",
						"value": "淮阳县实验中学"
					},
					{
						"label": "淮阳县第一中学",
						"value": "淮阳县第一中学"
					},
					{
						"label": "河南省淮阳中学",
						"value": "河南省淮阳中学"
					}
				]
			},
			{
				"label": "川汇区",
				"value": "川汇区",
				"children": [
					{
						"label": "周口文昌中学",
						"value": "周口文昌中学"
					},
					{
						"label": "周口颖河高级中学",
						"value": "周口颖河高级中学"
					},
					{
						"label": "周口市锦诚高级中学",
						"value": "周口市锦诚高级中学"
					},
					{
						"label": "周口高中",
						"value": "周口高中"
					},
					{
						"label": "周口市第一高级中学",
						"value": "周口市第一高级中学"
					},
					{
						"label": "周口市第二高级中学",
						"value": "周口市第二高级中学"
					},
					{
						"label": "周口恒大中学",
						"value": "周口恒大中学"
					},
					{
						"label": "周口龙水高级中学",
						"value": "周口龙水高级中学"
					},
					{
						"label": "周口育新高级中学",
						"value": "周口育新高级中学"
					}
				]
			},
			{
				"label": "郸城县",
				"value": "郸城县",
				"children": [
					{
						"label": "郸城县第一中学",
						"value": "郸城县第一中学"
					},
					{
						"label": "郸城县英才中学高中部",
						"value": "郸城县英才中学高中部"
					},
					{
						"label": "郸城县第一高级中学",
						"value": "郸城县第一高级中学"
					},
					{
						"label": "郸城县第二高级中学",
						"value": "郸城县第二高级中学"
					},
					{
						"label": "郸城县第三高级中学",
						"value": "郸城县第三高级中学"
					},
					{
						"label": "郸城县希望高级中学",
						"value": "郸城县希望高级中学"
					},
					{
						"label": "郸城县才源高级中学",
						"value": "郸城县才源高级中学"
					},
					{
						"label": "郸城县实验高中",
						"value": "郸城县实验高中"
					}
				]
			},
			{
				"label": "太康县",
				"value": "太康县",
				"children": [
					{
						"label": "太康县振华高级中学",
						"value": "太康县振华高级中学"
					},
					{
						"label": "太康县高贤高中",
						"value": "太康县高贤高中"
					},
					{
						"label": "太康县第三高级中学",
						"value": "太康县第三高级中学"
					},
					{
						"label": "太康县第二高级中学",
						"value": "太康县第二高级中学"
					},
					{
						"label": "太康县第一高级中学",
						"value": "太康县第一高级中学"
					},
					{
						"label": "河南华夏外国语高级中学",
						"value": "河南华夏外国语高级中学"
					}
				]
			},
			{
				"label": "鹿邑县",
				"value": "鹿邑县",
				"children": [
					{
						"label": "鹿邑县弘道学校",
						"value": "鹿邑县弘道学校"
					},
					{
						"label": "鹿邑县高级中学",
						"value": "鹿邑县高级中学"
					},
					{
						"label": "鹿邑县第二高级中学",
						"value": "鹿邑县第二高级中学"
					},
					{
						"label": "鹿邑县伯阳双语学校",
						"value": "鹿邑县伯阳双语学校"
					},
					{
						"label": "鹿邑县远志高级中学",
						"value": "鹿邑县远志高级中学"
					},
					{
						"label": "鹿邑县第三高级中学校",
						"value": "鹿邑县第三高级中学校"
					}
				]
			},
			{
				"label": "扶沟县",
				"value": "扶沟县",
				"children": [
					{
						"label": "扶沟县第二高级中学",
						"value": "扶沟县第二高级中学"
					},
					{
						"label": "扶沟县包屯高级中学",
						"value": "扶沟县包屯高级中学"
					},
					{
						"label": "扶沟县高级中学",
						"value": "扶沟县高级中学"
					},
					{
						"label": "扶沟县县直高级中学",
						"value": "扶沟县县直高级中学"
					}
				]
			},
			{
				"label": "商水县",
				"value": "商水县",
				"children": [
					{
						"label": "商水县第二高中",
						"value": "商水县第二高中"
					},
					{
						"label": "商水县第一高中",
						"value": "商水县第一高中"
					},
					{
						"label": "周口市中英文学校",
						"value": "周口市中英文学校"
					},
					{
						"label": "商水县第三中学",
						"value": "商水县第三中学"
					},
					{
						"label": "周口中学",
						"value": "周口中学"
					}
				]
			},
			{
				"label": "项城市",
				"value": "项城市",
				"children": [
					{
						"label": "项城市第一高级中学",
						"value": "项城市第一高级中学"
					},
					{
						"label": "项城市第二高级中学",
						"value": "项城市第二高级中学"
					},
					{
						"label": "项城市第三高级中学",
						"value": "项城市第三高级中学"
					},
					{
						"label": "项城市正泰博文高级中学",
						"value": "项城市正泰博文高级中学"
					}
				]
			},
			{
				"label": "西华县",
				"value": "西华县",
				"children": [
					{
						"label": "西华县第一高级中学",
						"value": "西华县第一高级中学"
					},
					{
						"label": "西华县第二高级中学",
						"value": "西华县第二高级中学"
					},
					{
						"label": "西华县第三高级中学",
						"value": "西华县第三高级中学"
					},
					{
						"label": "河南省黄泛区农场高级中学",
						"value": "河南省黄泛区农场高级中学"
					}
				]
			},
			{
				"label": "沈丘县",
				"value": "沈丘县",
				"children": [
					{
						"label": "沈丘县第一高级中学",
						"value": "沈丘县第一高级中学"
					},
					{
						"label": "沈丘县第二高级中学",
						"value": "沈丘县第二高级中学"
					},
					{
						"label": "沈丘县第三高级中学",
						"value": "沈丘县第三高级中学"
					},
					{
						"label": "沈丘县县直高中",
						"value": "沈丘县县直高中"
					},
					{
						"label": "沈丘县醒民高级中学",
						"value": "沈丘县醒民高级中学"
					}
				]
			}
		]
	},
	{
		"value": "漯河",
		"label": "漯河",
		"children": [
			{
				"label": "西城区",
				"value": "西城区",
				"children": [
					{
						"label": "漯河市宇华实验学校",
						"value": "漯河市宇华实验学校"
					}
				]
			},
			{
				"label": "舞阳县",
				"value": "舞阳县",
				"children": [
					{
						"label": "舞阳县第一高级中学",
						"value": "舞阳县第一高级中学"
					},
					{
						"label": "舞阳县第二高级中学",
						"value": "舞阳县第二高级中学"
					},
					{
						"label": "舞阳县春雨国文学校",
						"value": "舞阳县春雨国文学校"
					}
				]
			},
			{
				"label": "郾城区",
				"value": "郾城区",
				"children": [
					{
						"label": "漯河市崇文中学",
						"value": "漯河市崇文中学"
					},
					{
						"label": "漯河市郾城高级中学",
						"value": "漯河市郾城高级中学"
					},
					{
						"label": "漯河市郾城区实验高级中学",
						"value": "漯河市郾城区实验高级中学"
					},
					{
						"label": "漯河市第四高级中学",
						"value": "漯河市第四高级中学"
					},
					{
						"label": "漯河市第五高级中学",
						"value": "漯河市第五高级中学"
					}
				]
			},
			{
				"label": "召陵区",
				"value": "召陵区",
				"children": [
					{
						"label": "漯河市许慎高级中学",
						"value": "漯河市许慎高级中学"
					},
					{
						"label": "舞阳县第三高级中学",
						"value": "舞阳县第三高级中学"
					},
					{
						"label": "漯河市第二高级中学",
						"value": "漯河市第二高级中学"
					},
					{
						"label": "漯河市第三高级中学",
						"value": "漯河市第三高级中学"
					}
				]
			},
			{
				"label": "临颍县",
				"value": "临颍县",
				"children": [
					{
						"label": "临颍县博雅学校",
						"value": "临颍县博雅学校"
					},
					{
						"label": "临颍县第一高级中学",
						"value": "临颍县第一高级中学"
					},
					{
						"label": "临颍县南街高级中学",
						"value": "临颍县南街高级中学"
					},
					{
						"label": "临颍县第二高级中学",
						"value": "临颍县第二高级中学"
					},
					{
						"label": "临颍县第三高级中学",
						"value": "临颍县第三高级中学"
					}
				]
			},
			{
				"label": "源汇区",
				"value": "源汇区",
				"children": [
					{
						"label": "漯河市实验高级中学",
						"value": "漯河市实验高级中学"
					},
					{
						"label": "漯河市高级中学",
						"value": "漯河市高级中学"
					}
				]
			}
		]
	},
	{
		"value": "许昌",
		"label": "许昌",
		"children": [
			{
				"label": "魏都区",
				"value": "魏都区",
				"children": [
					{
						"label": "许昌宇华实验学校",
						"value": "许昌宇华实验学校"
					},
					{
						"label": "许昌市第八中学",
						"value": "许昌市第八中学"
					},
					{
						"label": "河南省实验学校许昌中学",
						"value": "河南省实验学校许昌中学"
					},
					{
						"label": "许昌市第三高级中学",
						"value": "许昌市第三高级中学"
					},
					{
						"label": "河南省许昌实验中学",
						"value": "河南省许昌实验中学"
					},
					{
						"label": "许昌第二高级中学",
						"value": "许昌第二高级中学"
					},
					{
						"label": "许昌高级中学",
						"value": "许昌高级中学"
					}
				]
			},
			{
				"label": "襄城县",
				"value": "襄城县",
				"children": [
					{
						"label": "襄城县库庄镇育人国际学校",
						"value": "襄城县库庄镇育人国际学校"
					},
					{
						"label": "襄城县清华园学校",
						"value": "襄城县清华园学校"
					},
					{
						"label": "襄城县白塔寺高级中学",
						"value": "襄城县白塔寺高级中学"
					},
					{
						"label": "襄城县第三高级中学",
						"value": "襄城县第三高级中学"
					},
					{
						"label": "河南省襄城高中",
						"value": "河南省襄城高中"
					},
					{
						"label": "襄城县实验高级中学",
						"value": "襄城县实验高级中学"
					}
				]
			},
			{
				"label": "禹州市",
				"value": "禹州市",
				"children": [
					{
						"label": "禹州市第一高级中学",
						"value": "禹州市第一高级中学"
					},
					{
						"label": "禹州市高级中学",
						"value": "禹州市高级中学"
					},
					{
						"label": "北大公学禹州国际学校高中部",
						"value": "北大公学禹州国际学校高中部"
					},
					{
						"label": "禹州市南区学校",
						"value": "禹州市南区学校"
					},
					{
						"label": "禹州市第四高级中学",
						"value": "禹州市第四高级中学"
					}
				]
			},
			{
				"label": "鄢陵县",
				"value": "鄢陵县",
				"children": [
					{
						"label": "鄢陵县第三高级中学",
						"value": "鄢陵县第三高级中学"
					},
					{
						"label": "郑州国庆中学花都学校",
						"value": "郑州国庆中学花都学校"
					},
					{
						"label": "鄢陵县第一高级中学",
						"value": "鄢陵县第一高级中学"
					},
					{
						"label": "鄢陵县第二高级中学",
						"value": "鄢陵县第二高级中学"
					},
					{
						"label": "鄢陵县华清高级中学",
						"value": "鄢陵县华清高级中学"
					},
					{
						"label": "鄢陵县实验高级中学",
						"value": "鄢陵县实验高级中学"
					}
				]
			},
			{
				"label": "建安区",
				"value": "建安区",
				"children": [
					{
						"label": "许昌市建安区金鼎高级中学",
						"value": "许昌市建安区金鼎高级中学"
					},
					{
						"label": "许昌市东城高级中学",
						"value": "许昌市东城高级中学"
					},
					{
						"label": "许昌市建安区第二高级中学",
						"value": "许昌市建安区第二高级中学"
					},
					{
						"label": "许昌市建安区第一高级中学",
						"value": "许昌市建安区第一高级中学"
					},
					{
						"label": "许昌市建安区第三高级中学",
						"value": "许昌市建安区第三高级中学"
					},
					{
						"label": "许昌市建安区第四高级中学",
						"value": "许昌市建安区第四高级中学"
					}
				]
			},
			{
				"label": "长葛市",
				"value": "长葛市",
				"children": [
					{
						"label": "长葛市第三高级中学",
						"value": "长葛市第三高级中学"
					},
					{
						"label": "长葛市第一高级中学",
						"value": "长葛市第一高级中学"
					},
					{
						"label": "长葛市第二高级中学",
						"value": "长葛市第二高级中学"
					},
					{
						"label": "长葛市第三实验高级中学",
						"value": "长葛市第三实验高级中学"
					},
					{
						"label": "长葛市民办实验中学",
						"value": "长葛市民办实验中学"
					}
				]
			}
		]
	},
	{
		"value": "驻马店",
		"label": "驻马店",
		"children": [
			{
				"label": "确山县",
				"value": "确山县",
				"children": [
					{
						"label": "确山县尚城亿达双语学校",
						"value": "确山县尚城亿达双语学校"
					},
					{
						"label": "河南省确山县第一高级中学",
						"value": "河南省确山县第一高级中学"
					},
					{
						"label": "确山县第二高级中学",
						"value": "确山县第二高级中学"
					}
				]
			},
			{
				"label": "驿城区",
				"value": "驿城区",
				"children": [
					{
						"label": "驻马店市树人国际学校",
						"value": "驻马店市树人国际学校"
					},
					{
						"label": "驻马店市驿城区板桥高级中学",
						"value": "驻马店市驿城区板桥高级中学"
					},
					{
						"label": "河南省驻马店市第一高级中学",
						"value": "河南省驻马店市第一高级中学"
					},
					{
						"label": "驻马店经济开发区高级中学",
						"value": "驻马店经济开发区高级中学"
					},
					{
						"label": "河南省驻马店高级中学",
						"value": "河南省驻马店高级中学"
					},
					{
						"label": "驻马店市第二高级中学",
						"value": "驻马店市第二高级中学"
					},
					{
						"label": "驻马店市第三高级中学",
						"value": "驻马店市第三高级中学"
					}
				]
			},
			{
				"label": "新蔡县",
				"value": "新蔡县",
				"children": [
					{
						"label": "新蔡县明英中学",
						"value": "新蔡县明英中学"
					},
					{
						"label": "新蔡县实验中学",
						"value": "新蔡县实验中学"
					},
					{
						"label": "新蔡县第二高级中学",
						"value": "新蔡县第二高级中学"
					},
					{
						"label": "新蔡县第一高级中学",
						"value": "新蔡县第一高级中学"
					},
					{
						"label": "新蔡县第三高级中学",
						"value": "新蔡县第三高级中学"
					}
				]
			},
			{
				"label": "泌阳县",
				"value": "泌阳县",
				"children": [
					{
						"label": "泌阳县第一高级中学",
						"value": "泌阳县第一高级中学"
					},
					{
						"label": "泌阳县第二高级中学",
						"value": "泌阳县第二高级中学"
					},
					{
						"label": "泌阳县泌阳中学",
						"value": "泌阳县泌阳中学"
					}
				]
			},
			{
				"label": "上蔡县",
				"value": "上蔡县",
				"children": [
					{
						"label": "上蔡县万象中学",
						"value": "上蔡县万象中学"
					},
					{
						"label": "上蔡中学",
						"value": "上蔡中学"
					},
					{
						"label": "北大公学实验学校",
						"value": "北大公学实验学校"
					},
					{
						"label": "上蔡县第二高级中学",
						"value": "上蔡县第二高级中学"
					},
					{
						"label": "上蔡县第一高级中学",
						"value": "上蔡县第一高级中学"
					},
					{
						"label": "上蔡县苏豫中学",
						"value": "上蔡县苏豫中学"
					}
				]
			},
			{
				"label": "正阳县",
				"value": "正阳县",
				"children": [
					{
						"label": "驻马店市正阳县育才外国语学校",
						"value": "驻马店市正阳县育才外国语学校"
					},
					{
						"label": "驻马店市正阳县第二高级中学",
						"value": "驻马店市正阳县第二高级中学"
					},
					{
						"label": "驻马店市正阳县高级中学",
						"value": "驻马店市正阳县高级中学"
					},
					{
						"label": "驻马店市正阳县第一高级中学",
						"value": "驻马店市正阳县第一高级中学"
					}
				]
			},
			{
				"label": "汝南县",
				"value": "汝南县",
				"children": [
					{
						"label": "汝南县第二高级中学",
						"value": "汝南县第二高级中学"
					},
					{
						"label": "河南省汝南高级中学",
						"value": "河南省汝南高级中学"
					},
					{
						"label": "汝南县双语学校",
						"value": "汝南县双语学校"
					}
				]
			},
			{
				"label": "西平县",
				"value": "西平县",
				"children": [
					{
						"label": "西平县高级中学",
						"value": "西平县高级中学"
					},
					{
						"label": "河南省西平县杨庄高级中学",
						"value": "河南省西平县杨庄高级中学"
					},
					{
						"label": "西平县第七初级中学",
						"value": "西平县第七初级中学"
					}
				]
			},
			{
				"label": "遂平县",
				"value": "遂平县",
				"children": [
					{
						"label": "遂平县第二高级中学",
						"value": "遂平县第二高级中学"
					},
					{
						"label": "遂平县遂平中学",
						"value": "遂平县遂平中学"
					},
					{
						"label": "遂平县第一高级中学",
						"value": "遂平县第一高级中学"
					}
				]
			},
			{
				"label": "平舆县",
				"value": "平舆县",
				"children": [
					{
						"label": "平舆县第三高级中学",
						"value": "平舆县第三高级中学"
					},
					{
						"label": "平舆县实验中学",
						"value": "平舆县实验中学"
					},
					{
						"label": "平舆县第一高级中学",
						"value": "平舆县第一高级中学"
					},
					{
						"label": "平舆县第二高级中学",
						"value": "平舆县第二高级中学"
					}
				]
			}
		]
	},
	{
		"value": "信阳",
		"label": "信阳",
		"children": [
			{
				"label": "固始县",
				"value": "固始县",
				"children": [
					{
						"label": "固始县永和中学",
						"value": "固始县永和中学"
					},
					{
						"label": "固始县文星学校",
						"value": "固始县文星学校"
					},
					{
						"label": "固始县外国语高中",
						"value": "固始县外国语高中"
					},
					{
						"label": "固始县职业教育中心中学部",
						"value": "固始县职业教育中心中学部"
					},
					{
						"label": "固始县第一中学（新校区）",
						"value": "固始县第一中学（新校区）"
					},
					{
						"label": "固始县第一高级中学",
						"value": "固始县第一高级中学"
					},
					{
						"label": "固始县慈济高级中学",
						"value": "固始县慈济高级中学"
					},
					{
						"label": "固始县第二高级中学",
						"value": "固始县第二高级中学"
					},
					{
						"label": "信阳市实验高级中学",
						"value": "信阳市实验高级中学"
					},
					{
						"label": "固始县高级中学",
						"value": "固始县高级中学"
					},
					{
						"label": "固始县永和高级中学",
						"value": "固始县永和高级中学"
					},
					{
						"label": "固始县希望高级中学",
						"value": "固始县希望高级中学"
					},
					{
						"label": "固始县蓼城中学",
						"value": "固始县蓼城中学"
					},
					{
						"label": "固始县往流镇中学",
						"value": "固始县往流镇中学"
					}
				]
			},
			{
				"label": "淮滨县",
				"value": "淮滨县",
				"children": [
					{
						"label": "淮滨县滨城学校",
						"value": "淮滨县滨城学校"
					},
					{
						"label": "河南省淮滨县第一中学",
						"value": "河南省淮滨县第一中学"
					},
					{
						"label": "淮滨县第二高级中学",
						"value": "淮滨县第二高级中学"
					},
					{
						"label": "河南省淮滨高级中学",
						"value": "河南省淮滨高级中学"
					},
					{
						"label": "淮滨外国语学校",
						"value": "淮滨外国语学校"
					}
				]
			},
			{
				"label": "新县",
				"value": "新县",
				"children": [
					{
						"label": "新县高级中学",
						"value": "新县高级中学"
					},
					{
						"label": "新县第二高级中学",
						"value": "新县第二高级中学"
					}
				]
			},
			{
				"label": "商城县",
				"value": "商城县",
				"children": [
					{
						"label": "商城县观庙高中",
						"value": "商城县观庙高中"
					},
					{
						"label": "商城县钟铺高中",
						"value": "商城县钟铺高中"
					},
					{
						"label": "商城县余集高中",
						"value": "商城县余集高中"
					},
					{
						"label": "商城县达权店高中",
						"value": "商城县达权店高中"
					},
					{
						"label": "商城县丰集高中",
						"value": "商城县丰集高中"
					},
					{
						"label": "商城县上石桥高中",
						"value": "商城县上石桥高中"
					},
					{
						"label": "商城县高级中学",
						"value": "商城县高级中学"
					},
					{
						"label": "商城县第三高级中学",
						"value": "商城县第三高级中学"
					}
				]
			},
			{
				"label": "潢川县",
				"value": "潢川县",
				"children": [
					{
						"label": "潢川县高级中学分校",
						"value": "潢川县高级中学分校"
					},
					{
						"label": "潢川县第一高级中学",
						"value": "潢川县第一高级中学"
					},
					{
						"label": "潢川高级中学",
						"value": "潢川高级中学"
					},
					{
						"label": "信阳市第七实验高中",
						"value": "信阳市第七实验高中"
					},
					{
						"label": "潢川县春申中学",
						"value": "潢川县春申中学"
					},
					{
						"label": "潢川县第一中学",
						"value": "潢川县第一中学"
					},
					{
						"label": "潢川县双柳高中",
						"value": "潢川县双柳高中"
					},
					{
						"label": "潢川县黄冈实验学校",
						"value": "潢川县黄冈实验学校"
					},
					{
						"label": "潢川县行知高中",
						"value": "潢川县行知高中"
					}
				]
			},
			{
				"label": "平桥区",
				"value": "平桥区",
				"children": [
					{
						"label": "河南省信阳市第六高级中学",
						"value": "河南省信阳市第六高级中学"
					},
					{
						"label": "河南省信阳市第四高级中学",
						"value": "河南省信阳市第四高级中学"
					},
					{
						"label": "信阳市平桥区实验高级中学",
						"value": "信阳市平桥区实验高级中学"
					},
					{
						"label": "河南省信阳市第二高级中学",
						"value": "河南省信阳市第二高级中学"
					},
					{
						"label": "信阳大别山高级中学",
						"value": "信阳大别山高级中学"
					}
				]
			},
			{
				"label": "息县",
				"value": "息县",
				"children": [
					{
						"label": "华中师范大学附属息县高级中学",
						"value": "华中师范大学附属息县高级中学"
					},
					{
						"label": "息县第一高级中学",
						"value": "息县第一高级中学"
					},
					{
						"label": "息县第二高级中学",
						"value": "息县第二高级中学"
					}
				]
			},
			{
				"label": "光山县",
				"value": "光山县",
				"children": [
					{
						"label": "光山县高级中学",
						"value": "光山县高级中学"
					},
					{
						"label": "光山县第一高级中学",
						"value": "光山县第一高级中学"
					},
					{
						"label": "光山县第三高级中学",
						"value": "光山县第三高级中学"
					},
					{
						"label": "光山县第二高级中学",
						"value": "光山县第二高级中学"
					},
					{
						"label": "光山县孙铁铺镇高级中学",
						"value": "光山县孙铁铺镇高级中学"
					},
					{
						"label": "光山县马畈高级中学",
						"value": "光山县马畈高级中学"
					},
					{
						"label": "光山县文殊高级中学",
						"value": "光山县文殊高级中学"
					},
					{
						"label": "光山县泼河镇高级中学",
						"value": "光山县泼河镇高级中学"
					},
					{
						"label": "光山县白雀高级中学",
						"value": "光山县白雀高级中学"
					}
				]
			},
			{
				"label": "浉河区",
				"value": "浉河区",
				"children": [
					{
						"label": "河南省信阳市第七高级中学",
						"value": "河南省信阳市第七高级中学"
					},
					{
						"label": "信阳市第三实验高级中学",
						"value": "信阳市第三实验高级中学"
					},
					{
						"label": "河南省信阳市第八高级中学",
						"value": "河南省信阳市第八高级中学"
					},
					{
						"label": "河南省信阳市第十高级中学",
						"value": "河南省信阳市第十高级中学"
					},
					{
						"label": "河南省信阳高级中学",
						"value": "河南省信阳高级中学"
					},
					{
						"label": "信阳市第一高级中学",
						"value": "信阳市第一高级中学"
					},
					{
						"label": "河南省信阳市第五高级中学",
						"value": "河南省信阳市第五高级中学"
					},
					{
						"label": "河南宋基信阳实验中学",
						"value": "河南宋基信阳实验中学"
					}
				]
			},
			{
				"label": "罗山县",
				"value": "罗山县",
				"children": [
					{
						"label": "河南省罗山高级中学",
						"value": "河南省罗山高级中学"
					},
					{
						"label": "罗山县周党高级中学",
						"value": "罗山县周党高级中学"
					},
					{
						"label": "河南省罗山县涩港高级中学",
						"value": "河南省罗山县涩港高级中学"
					},
					{
						"label": "河南省罗山县楠杆高级中学",
						"value": "河南省罗山县楠杆高级中学"
					},
					{
						"label": "罗山县第二高级中学",
						"value": "罗山县第二高级中学"
					},
					{
						"label": "河南省罗山县莽张高级中学",
						"value": "河南省罗山县莽张高级中学"
					}
				]
			}
		]
	},
	{
		"value": "新乡",
		"label": "新乡",
		"children": [
			{
				"label": "红旗区",
				"value": "红旗区",
				"children": [
					{
						"label": "新乡市河南师大附中双语国际学校",
						"value": "新乡市河南师大附中双语国际学校"
					},
					{
						"label": "新乡市冠英高级中学",
						"value": "新乡市冠英高级中学"
					},
					{
						"label": "新乡市新誉佳高级中学",
						"value": "新乡市新誉佳高级中学"
					},
					{
						"label": "新乡市格雷特综合高中",
						"value": "新乡市格雷特综合高中"
					},
					{
						"label": "新乡市第四十五中学",
						"value": "新乡市第四十五中学"
					},
					{
						"label": "新乡市第一中学",
						"value": "新乡市第一中学"
					},
					{
						"label": "新乡市一中实验学校",
						"value": "新乡市一中实验学校"
					}
				]
			},
			{
				"label": "长垣县",
				"value": "长垣县",
				"children": [
					{
						"label": "长垣县东英学校",
						"value": "长垣县东英学校"
					},
					{
						"label": "长垣县高章士学校",
						"value": "长垣县高章士学校"
					},
					{
						"label": "长垣县凯杰中学",
						"value": "长垣县凯杰中学"
					},
					{
						"label": "长垣县育才高中",
						"value": "长垣县育才高中"
					},
					{
						"label": "长垣县行知学校",
						"value": "长垣县行知学校"
					},
					{
						"label": "长垣县银河学校",
						"value": "长垣县银河学校"
					},
					{
						"label": "长垣县第十中学",
						"value": "长垣县第十中学"
					},
					{
						"label": "长垣县第一高级中学",
						"value": "长垣县第一高级中学"
					}
				]
			},
			{
				"label": "原阳县",
				"value": "原阳县",
				"children": [
					{
						"label": "原阳县南街中学",
						"value": "原阳县南街中学"
					},
					{
						"label": "原阳县城关镇阳光中学",
						"value": "原阳县城关镇阳光中学"
					},
					{
						"label": "原阳县天润中学",
						"value": "原阳县天润中学"
					},
					{
						"label": "原阳县第三高级中学",
						"value": "原阳县第三高级中学"
					},
					{
						"label": "原阳县实验高级中学",
						"value": "原阳县实验高级中学"
					},
					{
						"label": "原阳县第四高级中学",
						"value": "原阳县第四高级中学"
					},
					{
						"label": "新乡市新鼎高级中学",
						"value": "新乡市新鼎高级中学"
					},
					{
						"label": "原阳县第一高级中学",
						"value": "原阳县第一高级中学"
					},
					{
						"label": "新乡市平原外国语学校",
						"value": "新乡市平原外国语学校"
					}
				]
			},
			{
				"label": "卫滨区",
				"value": "卫滨区",
				"children": [
					{
						"label": "新乡市铁路第二中学",
						"value": "新乡市铁路第二中学"
					},
					{
						"label": "新乡市美术高级中学",
						"value": "新乡市美术高级中学"
					},
					{
						"label": "新乡市第七中学",
						"value": "新乡市第七中学"
					},
					{
						"label": "新乡市铁路高级中学",
						"value": "新乡市铁路高级中学"
					}
				]
			},
			{
				"label": "获嘉县",
				"value": "获嘉县",
				"children": [
					{
						"label": "新乡市第四十二中学",
						"value": "新乡市第四十二中学"
					},
					{
						"label": "获嘉县开成高级中学",
						"value": "获嘉县开成高级中学"
					},
					{
						"label": "获嘉县苏豫中学",
						"value": "获嘉县苏豫中学"
					},
					{
						"label": "获嘉县高级中学",
						"value": "获嘉县高级中学"
					},
					{
						"label": "获嘉县第三中学",
						"value": "获嘉县第三中学"
					},
					{
						"label": "获嘉县第一中学",
						"value": "获嘉县第一中学"
					},
					{
						"label": "获嘉县第二中学",
						"value": "获嘉县第二中学"
					}
				]
			},
			{
				"label": "延津县",
				"value": "延津县",
				"children": [
					{
						"label": "延津县实验中学",
						"value": "延津县实验中学"
					},
					{
						"label": "延津县第一高级中学",
						"value": "延津县第一高级中学"
					},
					{
						"label": "延津县高级中学",
						"value": "延津县高级中学"
					},
					{
						"label": "延津城北中学",
						"value": "延津城北中学"
					}
				]
			},
			{
				"label": "凤泉区",
				"value": "凤泉区",
				"children": [
					{
						"label": "新乡十中英才学校",
						"value": "新乡十中英才学校"
					},
					{
						"label": "新乡市第十一中学",
						"value": "新乡市第十一中学"
					}
				]
			},
			{
				"label": "新乡县",
				"value": "新乡县",
				"children": [
					{
						"label": "新乡县高级中学",
						"value": "新乡县高级中学"
					},
					{
						"label": "新乡县第一中学",
						"value": "新乡县第一中学"
					},
					{
						"label": "新乡县龙泉中学",
						"value": "新乡县龙泉中学"
					},
					{
						"label": "新乡县刘庄学校",
						"value": "新乡县刘庄学校"
					},
					{
						"label": "新乡县冠英中学",
						"value": "新乡县冠英中学"
					},
					{
						"label": "新乡市辅豫实验高级中学",
						"value": "新乡市辅豫实验高级中学"
					}
				]
			},
			{
				"label": "封丘县",
				"value": "封丘县",
				"children": [
					{
						"label": "封丘县第八高级中学",
						"value": "封丘县第八高级中学"
					},
					{
						"label": "封丘县第三高级中学",
						"value": "封丘县第三高级中学"
					},
					{
						"label": "封丘县第七高级中学",
						"value": "封丘县第七高级中学"
					},
					{
						"label": "封丘县高级中学",
						"value": "封丘县高级中学"
					},
					{
						"label": "封丘县第一中学",
						"value": "封丘县第一中学"
					},
					{
						"label": "封丘县曹岗乡高级中学",
						"value": "封丘县曹岗乡高级中学"
					}
				]
			},
			{
				"label": "温县",
				"value": "温县",
				"children": [
					{
						"label": "温县第一高级中学",
						"value": "温县第一高级中学"
					},
					{
						"label": "温县新宇中学",
						"value": "温县新宇中学"
					},
					{
						"label": "温县实验高级中学",
						"value": "温县实验高级中学"
					}
				]
			},
			{
				"label": "巩义市",
				"value": "巩义市",
				"children": [
					{
						"label": "巩义市升华中学",
						"value": "巩义市升华中学"
					},
					{
						"label": "巩义市京师杜甫高级中学",
						"value": "巩义市京师杜甫高级中学"
					},
					{
						"label": "巩义市第二高级中学",
						"value": "巩义市第二高级中学"
					},
					{
						"label": "巩义市第三高级中学",
						"value": "巩义市第三高级中学"
					},
					{
						"label": "巩义市第四高级中学",
						"value": "巩义市第四高级中学"
					},
					{
						"label": "巩义市市直高级中学",
						"value": "巩义市市直高级中学"
					},
					{
						"label": "河南省巩义中学",
						"value": "河南省巩义中学"
					},
					{
						"label": "巩义市第一高级中学",
						"value": "巩义市第一高级中学"
					}
				]
			},
			{
				"label": "辉县市",
				"value": "辉县市",
				"children": [
					{
						"label": "辉县市第三高级中学",
						"value": "辉县市第三高级中学"
					},
					{
						"label": "辉县市孟庄中学",
						"value": "辉县市孟庄中学"
					},
					{
						"label": "辉县市第四高级中学",
						"value": "辉县市第四高级中学"
					},
					{
						"label": "辉县市第一高级中学",
						"value": "辉县市第一高级中学"
					},
					{
						"label": "辉县市第二高级中学",
						"value": "辉县市第二高级中学"
					},
					{
						"label": "辉县市高级中学",
						"value": "辉县市高级中学"
					}
				]
			},
			{
				"label": "牧野区",
				"value": "牧野区",
				"children": [
					{
						"label": "新乡市第三中学",
						"value": "新乡市第三中学"
					},
					{
						"label": "新乡市第二中学",
						"value": "新乡市第二中学"
					},
					{
						"label": "新乡市第十二中学",
						"value": "新乡市第十二中学"
					},
					{
						"label": "新乡市河南师范大学附属中学金龙学校",
						"value": "新乡市河南师范大学附属中学金龙学校"
					},
					{
						"label": "河南师范大学附属中学",
						"value": "河南师范大学附属中学"
					}
				]
			},
			{
				"label": "卫辉市",
				"value": "卫辉市",
				"children": [
					{
						"label": "卫辉市高级中学",
						"value": "卫辉市高级中学"
					},
					{
						"label": "卫辉市第一中学",
						"value": "卫辉市第一中学"
					},
					{
						"label": "卫辉市第一中学实验学校",
						"value": "卫辉市第一中学实验学校"
					}
				]
			},
			{
				"label": "长垣市",
				"value": "长垣市",
				"children": [
					{
						"label": "河南宏力学校",
						"value": "河南宏力学校"
					}
				]
			}
		]
	},
	{
		"value": "洛阳",
		"label": "洛阳",
		"children": [
			{
				"label": "涧西区",
				"value": "涧西区",
				"children": [
					{
						"label": "洛阳市东明外语实验学校",
						"value": "洛阳市东明外语实验学校"
					},
					{
						"label": "洛阳东方理工实验学校",
						"value": "洛阳东方理工实验学校"
					},
					{
						"label": "洛阳市第五十九中学",
						"value": "洛阳市第五十九中学"
					},
					{
						"label": "河南科技大学附属高级中学",
						"value": "河南科技大学附属高级中学"
					},
					{
						"label": "洛阳理工学院附属中学",
						"value": "洛阳理工学院附属中学"
					},
					{
						"label": "洛阳市第二中学",
						"value": "洛阳市第二中学"
					},
					{
						"label": "洛阳市第九中学",
						"value": "洛阳市第九中学"
					},
					{
						"label": "洛阳梅森学校",
						"value": "洛阳梅森学校"
					},
					{
						"label": "洛阳市宏瑞达学校",
						"value": "洛阳市宏瑞达学校"
					},
					{
						"label": "洛阳市第十五中学",
						"value": "洛阳市第十五中学"
					}
				]
			},
			{
				"label": "嵩县",
				"value": "嵩县",
				"children": [
					{
						"label": "河南省洛阳市嵩县第一高级中学",
						"value": "河南省洛阳市嵩县第一高级中学"
					},
					{
						"label": "嵩县第二高级中学",
						"value": "嵩县第二高级中学"
					},
					{
						"label": "嵩县第五高级中学",
						"value": "嵩县第五高级中学"
					},
					{
						"label": "河南省嵩县实验中学",
						"value": "河南省嵩县实验中学"
					},
					{
						"label": "嵩县江苏高级中学",
						"value": "嵩县江苏高级中学"
					}
				]
			},
			{
				"label": "洛龙区",
				"value": "洛龙区",
				"children": [
					{
						"label": "洛阳华洋国际学校",
						"value": "洛阳华洋国际学校"
					},
					{
						"label": "洛阳市第二实验中学",
						"value": "洛阳市第二实验中学"
					},
					{
						"label": "洛阳市第八中学",
						"value": "洛阳市第八中学"
					},
					{
						"label": "洛阳市第十二中学",
						"value": "洛阳市第十二中学"
					},
					{
						"label": "河南枫叶国际学校",
						"value": "河南枫叶国际学校"
					},
					{
						"label": "洛阳师范学院附属中学",
						"value": "洛阳师范学院附属中学"
					},
					{
						"label": "洛阳市第十四中学",
						"value": "洛阳市第十四中学"
					},
					{
						"label": "洛阳市第一高级中学",
						"value": "洛阳市第一高级中学"
					},
					{
						"label": "洛阳国际学校",
						"value": "洛阳国际学校"
					},
					{
						"label": "洛阳复兴学校",
						"value": "洛阳复兴学校"
					},
					{
						"label": "洛阳格致学校",
						"value": "洛阳格致学校"
					}
				]
			},
			{
				"label": "新安县",
				"value": "新安县",
				"children": [
					{
						"label": "新安县第三高级中学",
						"value": "新安县第三高级中学"
					},
					{
						"label": "新安县第一高级中学",
						"value": "新安县第一高级中学"
					},
					{
						"label": "新安县第二高级中学",
						"value": "新安县第二高级中学"
					},
					{
						"label": "洛阳欧亚国际双语学校",
						"value": "洛阳欧亚国际双语学校"
					},
					{
						"label": "洛阳市博达学校",
						"value": "洛阳市博达学校"
					},
					{
						"label": "新安县第四高级中学",
						"value": "新安县第四高级中学"
					}
				]
			},
			{
				"label": "宜阳县",
				"value": "宜阳县",
				"children": [
					{
						"label": "宜阳县第一实验高级中学",
						"value": "宜阳县第一实验高级中学"
					},
					{
						"label": "宜阳县第一高级中学",
						"value": "宜阳县第一高级中学"
					},
					{
						"label": "宜阳县第二高级中学",
						"value": "宜阳县第二高级中学"
					},
					{
						"label": "宜阳县双语实验学校",
						"value": "宜阳县双语实验学校"
					},
					{
						"label": "宜阳县艺术学校",
						"value": "宜阳县艺术学校"
					},
					{
						"label": "宜阳县江南实验学校",
						"value": "宜阳县江南实验学校"
					},
					{
						"label": "宜阳县屏阳中学",
						"value": "宜阳县屏阳中学"
					},
					{
						"label": "宜阳县第二实验高级中学",
						"value": "宜阳县第二实验高级中学"
					}
				]
			},
			{
				"label": "偃师市",
				"value": "偃师市",
				"children": [
					{
						"label": "偃师市实验高级中学",
						"value": "偃师市实验高级中学"
					},
					{
						"label": "偃师市高级中学",
						"value": "偃师市高级中学"
					},
					{
						"label": "偃师市第一高级中学",
						"value": "偃师市第一高级中学"
					},
					{
						"label": "偃师市第五高级中学",
						"value": "偃师市第五高级中学"
					},
					{
						"label": "洛阳偃师中成外国语学校",
						"value": "洛阳偃师中成外国语学校"
					}
				]
			},
			{
				"label": "伊川县",
				"value": "伊川县",
				"children": [
					{
						"label": "伊川县第二高级中学",
						"value": "伊川县第二高级中学"
					},
					{
						"label": "河南省伊川高中",
						"value": "河南省伊川高中"
					},
					{
						"label": "伊川县第四高级中学",
						"value": "伊川县第四高级中学"
					},
					{
						"label": "伊川县第三高级中学",
						"value": "伊川县第三高级中学"
					},
					{
						"label": "伊川县滨河高级中学",
						"value": "伊川县滨河高级中学"
					},
					{
						"label": "洛阳市伊河高中",
						"value": "洛阳市伊河高中"
					}
				]
			},
			{
				"label": "老城区",
				"value": "老城区",
				"children": [
					{
						"label": "洛阳市第四十中学",
						"value": "洛阳市第四十中学"
					}
				]
			},
			{
				"label": "西工区",
				"value": "西工区",
				"children": [
					{
						"label": "洛阳外国语学校",
						"value": "洛阳外国语学校"
					},
					{
						"label": "洛阳市第十九中学",
						"value": "洛阳市第十九中学"
					},
					{
						"label": "洛阳长春中学",
						"value": "洛阳长春中学"
					}
				]
			},
			{
				"label": "孟津县",
				"value": "孟津县",
				"children": [
					{
						"label": "孟津县第二高级中学",
						"value": "孟津县第二高级中学"
					},
					{
						"label": "孟津县第一高级中学",
						"value": "孟津县第一高级中学"
					},
					{
						"label": "孟津县平乐高级中学",
						"value": "孟津县平乐高级中学"
					},
					{
						"label": "孟津县老城高级中学",
						"value": "孟津县老城高级中学"
					},
					{
						"label": "北大孟津附属实验学校",
						"value": "北大孟津附属实验学校"
					}
				]
			},
			{
				"label": "瀍河回族区",
				"value": "瀍河回族区",
				"children": [
					{
						"label": "洛阳佳林学校",
						"value": "洛阳佳林学校"
					},
					{
						"label": "洛阳市第一中学",
						"value": "洛阳市第一中学"
					},
					{
						"label": "洛阳市第四中学",
						"value": "洛阳市第四中学"
					},
					{
						"label": "洛阳市第四十六中学",
						"value": "洛阳市第四十六中学"
					},
					{
						"label": "洛阳市回民中学",
						"value": "洛阳市回民中学"
					},
					{
						"label": "洛阳华夏外国语学校",
						"value": "洛阳华夏外国语学校"
					},
					{
						"label": "洛阳东方外国语学校",
						"value": "洛阳东方外国语学校"
					}
				]
			},
			{
				"label": "洛宁县",
				"value": "洛宁县",
				"children": [
					{
						"label": "洛宁县第一高级中学",
						"value": "洛宁县第一高级中学"
					},
					{
						"label": "洛宁县高级中学",
						"value": "洛宁县高级中学"
					},
					{
						"label": "洛宁县崤光高级中学",
						"value": "洛宁县崤光高级中学"
					}
				]
			},
			{
				"label": "西工区",
				"value": "西工区",
				"children": [
					{
						"label": "洛阳市第三中学",
						"value": "洛阳市第三中学"
					}
				]
			},
			{
				"label": "吉利区",
				"value": "吉利区",
				"children": [
					{
						"label": "洛阳市第四十三中学",
						"value": "洛阳市第四十三中学"
					},
					{
						"label": "洛阳新学道高级中学",
						"value": "洛阳新学道高级中学"
					}
				]
			},
			{
				"label": "栾川县",
				"value": "栾川县",
				"children": [
					{
						"label": "栾川县实验高中",
						"value": "栾川县实验高中"
					},
					{
						"label": "栾川县第一高级中学",
						"value": "栾川县第一高级中学"
					},
					{
						"label": "栾川县第二高级中学",
						"value": "栾川县第二高级中学"
					},
					{
						"label": "栾川朝阳中学",
						"value": "栾川朝阳中学"
					}
				]
			},
			{
				"label": "汝阳县",
				"value": "汝阳县",
				"children": [
					{
						"label": "汝阳县第一高级中学",
						"value": "汝阳县第一高级中学"
					},
					{
						"label": "汝阳县实验高中",
						"value": "汝阳县实验高中"
					},
					{
						"label": "汝阳县第二高级中学",
						"value": "汝阳县第二高级中学"
					}
				]
			},
			{
				"label": "伊滨区",
				"value": "伊滨区",
				"children": [
					{
						"label": "洛阳伊滨国瑾实验学校",
						"value": "洛阳伊滨国瑾实验学校"
					}
				]
			}
		]
	},
	{
		"value": "郑州",
		"label": "郑州",
		"children": [
			{
				"label": "管城回族区",
				"value": "管城回族区",
				"children": [
					{
						"label": "郑州国庆中学",
						"value": "郑州国庆中学"
					},
					{
						"label": "郑州市第五十三中学",
						"value": "郑州市第五十三中学"
					},
					{
						"label": "郑州市第二十九中学",
						"value": "郑州市第二十九中学"
					},
					{
						"label": "郑州市回民中学",
						"value": "郑州市回民中学"
					},
					{
						"label": "郑州慧民外语学校",
						"value": "郑州慧民外语学校"
					},
					{
						"label": "郑州市第一0一中学",
						"value": "郑州市第一0一中学"
					},
					{
						"label": "郑州市第一0三中学",
						"value": "郑州市第一0三中学"
					},
					{
						"label": "郑州市第五中学",
						"value": "郑州市第五中学"
					},
					{
						"label": "郑州市扶轮外国语学校",
						"value": "郑州市扶轮外国语学校"
					},
					{
						"label": "郑州市第十一中学",
						"value": "郑州市第十一中学"
					},
					{
						"label": "郑州嵩阳中学",
						"value": "郑州嵩阳中学"
					},
					{
						"label": "郑州市体育中学",
						"value": "郑州市体育中学"
					}
				]
			},
			{
				"label": "中牟县",
				"value": "中牟县",
				"children": [
					{
						"label": "郑州东枫外国语学校",
						"value": "郑州东枫外国语学校"
					},
					{
						"label": "郑州市郑开学校",
						"value": "郑州市郑开学校"
					},
					{
						"label": "中牟县第四高级中学",
						"value": "中牟县第四高级中学"
					},
					{
						"label": "中牟县第一高级中学",
						"value": "中牟县第一高级中学"
					},
					{
						"label": "中牟县第二高级中学",
						"value": "中牟县第二高级中学"
					},
					{
						"label": "中牟县弘毅高级中学",
						"value": "中牟县弘毅高级中学"
					},
					{
						"label": "中牟县第三高级中学",
						"value": "中牟县第三高级中学"
					},
					{
						"label": "郑州华夏中学",
						"value": "郑州华夏中学"
					}
				]
			},
			{
				"label": "二七区",
				"value": "二七区",
				"children": [
					{
						"label": "郑州实验外国语中学",
						"value": "郑州实验外国语中学"
					},
					{
						"label": "郑州市第七十四中学",
						"value": "郑州市第七十四中学"
					},
					{
						"label": "郑州市第十八中学",
						"value": "郑州市第十八中学"
					},
					{
						"label": "郑州啟思中学",
						"value": "郑州啟思中学"
					},
					{
						"label": "郑州市第四中学",
						"value": "郑州市第四中学"
					},
					{
						"label": "郑州市京广实验学校",
						"value": "郑州市京广实验学校"
					},
					{
						"label": "郑州市第四十四中学",
						"value": "郑州市第四十四中学"
					},
					{
						"label": "郑州市第一0二中学",
						"value": "郑州市第一0二中学"
					},
					{
						"label": "郑州市第二中学",
						"value": "郑州市第二中学"
					},
					{
						"label": "郑州爱中学校",
						"value": "郑州爱中学校"
					},
					{
						"label": "郑州市第一0七中学",
						"value": "郑州市第一0七中学"
					},
					{
						"label": "郑州市第十四中学",
						"value": "郑州市第十四中学"
					},
					{
						"label": "郑州大学第一附属中学",
						"value": "郑州大学第一附属中学"
					},
					{
						"label": "郑州市桃园中学",
						"value": "郑州市桃园中学"
					},
					{
						"label": "郑州市陇海中学",
						"value": "郑州市陇海中学"
					},
					{
						"label": "郑州中学生学习报社附属学校",
						"value": "郑州中学生学习报社附属学校"
					},
					{
						"label": "黄河科技学院附属中学",
						"value": "黄河科技学院附属中学"
					},
					{
						"label": "郑州市第一0六中学",
						"value": "郑州市第一0六中学"
					}
				]
			},
			{
				"label": "金水区",
				"value": "金水区",
				"children": [
					{
						"label": "郑州新奇中学",
						"value": "郑州新奇中学"
					},
					{
						"label": "郑州女子高级中学",
						"value": "郑州女子高级中学"
					},
					{
						"label": "郑州优胜实验中学",
						"value": "郑州优胜实验中学"
					},
					{
						"label": "郑州新意中学",
						"value": "郑州新意中学"
					},
					{
						"label": "郑州市第四十七中学",
						"value": "郑州市第四十七中学"
					},
					{
						"label": "郑州一八联合国际学校",
						"value": "郑州一八联合国际学校"
					},
					{
						"label": "郑州市为民高中",
						"value": "郑州市为民高中"
					},
					{
						"label": "郑州市第七中学",
						"value": "郑州市第七中学"
					},
					{
						"label": "郑州树人中学",
						"value": "郑州树人中学"
					},
					{
						"label": "郑州市第三十一中学",
						"value": "郑州市第三十一中学"
					},
					{
						"label": "郑州市第九中学",
						"value": "郑州市第九中学"
					},
					{
						"label": "郑州轻工业学院附属中学",
						"value": "郑州轻工业学院附属中学"
					},
					{
						"label": "河南省实验中学",
						"value": "河南省实验中学"
					},
					{
						"label": "河南省实验文博学校",
						"value": "河南省实验文博学校"
					},
					{
						"label": "郑州励德双语学校",
						"value": "郑州励德双语学校"
					},
					{
						"label": "郑州惠民中学",
						"value": "郑州惠民中学"
					},
					{
						"label": "河南建业外国语中学",
						"value": "河南建业外国语中学"
					},
					{
						"label": "郑州中奥维也纳音乐学校",
						"value": "郑州中奥维也纳音乐学校"
					},
					{
						"label": "郑州大学第二附属中学",
						"value": "郑州大学第二附属中学"
					},
					{
						"label": "郑州博爵国际学校",
						"value": "郑州博爵国际学校"
					},
					{
						"label": "郑州市思齐实验中学",
						"value": "郑州市思齐实验中学"
					},
					{
						"label": "郑州市勤礼外语中学",
						"value": "郑州市勤礼外语中学"
					}
				]
			},
			{
				"label": "新郑市",
				"value": "新郑市",
				"children": [
					{
						"label": "河南省新郑市第三中学",
						"value": "河南省新郑市第三中学"
					},
					{
						"label": "河南省新郑市第一中学",
						"value": "河南省新郑市第一中学"
					},
					{
						"label": "新郑市新郑高级中学",
						"value": "新郑市新郑高级中学"
					},
					{
						"label": "郑州中澳学校",
						"value": "郑州中澳学校"
					},
					{
						"label": "河南省新郑市第二中学",
						"value": "河南省新郑市第二中学"
					},
					{
						"label": "新郑市实验高级中学",
						"value": "新郑市实验高级中学"
					},
					{
						"label": "郑州市宇华实验学校",
						"value": "郑州市宇华实验学校"
					},
					{
						"label": "郑州市维纲中学",
						"value": "郑州市维纲中学"
					},
					{
						"label": "河南省实验学校裕鸿国际学校",
						"value": "河南省实验学校裕鸿国际学校"
					},
					{
						"label": "郑州剑桥中学",
						"value": "郑州剑桥中学"
					}
				]
			},
			{
				"label": "荥阳市",
				"value": "荥阳市",
				"children": [
					{
						"label": "郑州龙湖一中",
						"value": "郑州龙湖一中"
					},
					{
						"label": "荥阳市索河中学",
						"value": "荥阳市索河中学"
					},
					{
						"label": "郑州市西一中学",
						"value": "郑州市西一中学"
					},
					{
						"label": "郑州普海外国语学校",
						"value": "郑州普海外国语学校"
					},
					{
						"label": "荥阳市高级中学",
						"value": "荥阳市高级中学"
					},
					{
						"label": "荥阳市第二高级中学",
						"value": "荥阳市第二高级中学"
					},
					{
						"label": "荥阳市实验高中",
						"value": "荥阳市实验高中"
					}
				]
			},
			{
				"label": "中原区",
				"value": "中原区",
				"children": [
					{
						"label": "郑州津孚国际学校",
						"value": "郑州津孚国际学校"
					},
					{
						"label": "河南省郑州市第三十六中学",
						"value": "河南省郑州市第三十六中学"
					},
					{
						"label": "郑州市郑中国际学校",
						"value": "郑州市郑中国际学校"
					},
					{
						"label": "郑州枫杨外国语学校",
						"value": "郑州枫杨外国语学校"
					},
					{
						"label": "郑州领航实验学校",
						"value": "郑州领航实验学校"
					},
					{
						"label": "郑州西斯达城市森林学校",
						"value": "郑州西斯达城市森林学校"
					},
					{
						"label": "郑州学森实验学校",
						"value": "郑州学森实验学校"
					},
					{
						"label": "郑州外国语学校",
						"value": "郑州外国语学校"
					},
					{
						"label": "郑州外国语新枫杨学校",
						"value": "郑州外国语新枫杨学校"
					},
					{
						"label": "郑州市第十九中学",
						"value": "郑州市第十九中学"
					},
					{
						"label": "郑州市第二十四中学",
						"value": "郑州市第二十四中学"
					},
					{
						"label": "郑州市明德中学",
						"value": "郑州市明德中学"
					},
					{
						"label": "郑州市第十六中学",
						"value": "郑州市第十六中学"
					},
					{
						"label": "郑州二砂寄宿学校",
						"value": "郑州二砂寄宿学校"
					},
					{
						"label": "郑州中学",
						"value": "郑州中学"
					},
					{
						"label": "郑州市明新中学",
						"value": "郑州市明新中学"
					},
					{
						"label": "郑州市第一中学",
						"value": "郑州市第一中学"
					},
					{
						"label": "郑州市基石中学",
						"value": "郑州市基石中学"
					}
				]
			},
			{
				"label": "上街区",
				"value": "上街区",
				"children": [
					{
						"label": "郑州尚美中学",
						"value": "郑州尚美中学"
					},
					{
						"label": "郑州市第二外国语学校",
						"value": "郑州市第二外国语学校"
					},
					{
						"label": "郑州市上街实验高级中学",
						"value": "郑州市上街实验高级中学"
					}
				]
			},
			{
				"label": "惠济区",
				"value": "惠济区",
				"children": [
					{
						"label": "郑州市实验高级中学",
						"value": "郑州市实验高级中学"
					},
					{
						"label": "郑州星源外国语学校",
						"value": "郑州星源外国语学校"
					},
					{
						"label": "郑州四禾美术学校",
						"value": "郑州四禾美术学校"
					},
					{
						"label": "郑州市第二十中学",
						"value": "郑州市第二十中学"
					},
					{
						"label": "郑州市第十二中学",
						"value": "郑州市第十二中学"
					},
					{
						"label": "河南省体育中学",
						"value": "河南省体育中学"
					}
				]
			},
			{
				"label": "新密市",
				"value": "新密市",
				"children": [
					{
						"label": "新密市第二高级中学",
						"value": "新密市第二高级中学"
					},
					{
						"label": "新密市实验高级中学",
						"value": "新密市实验高级中学"
					},
					{
						"label": "新密市青屏高级中学",
						"value": "新密市青屏高级中学"
					},
					{
						"label": "新密市第一高级中学",
						"value": "新密市第一高级中学"
					},
					{
						"label": "新密市京密联谊学校",
						"value": "新密市京密联谊学校"
					}
				]
			},
			{
				"label": "登封市",
				"value": "登封市",
				"children": [
					{
						"label": "登封市嵩阳高级中学",
						"value": "登封市嵩阳高级中学"
					},
					{
						"label": "登封市外国语高级中学",
						"value": "登封市外国语高级中学"
					},
					{
						"label": "登封市第一高级中学",
						"value": "登封市第一高级中学"
					},
					{
						"label": "登封市实验高级中学",
						"value": "登封市实验高级中学"
					},
					{
						"label": "登封市少林中学",
						"value": "登封市少林中学"
					}
				]
			},
			{
				"label": "航空港区",
				"value": "航空港区",
				"children": [
					{
						"label": "郑州航空港区英迪国际学校",
						"value": "郑州航空港区英迪国际学校"
					},
					{
						"label": "郑州航空港区育人高级中学",
						"value": "郑州航空港区育人高级中学"
					},
					{
						"label": "郑州航空港区高级中学",
						"value": "郑州航空港区高级中学"
					}
				]
			},
			{
				"label": "郑东新区",
				"value": "郑东新区",
				"children": [
					{
						"label": "郑州市郑东新区外国语中学",
						"value": "郑州市郑东新区外国语中学"
					}
				]
			},
			{
				"label": "巩义市",
				"value": "巩义市",
				"children": [
					{
						"label": "巩义市升华中学",
						"value": "巩义市升华中学"
					},
					{
						"label": "巩义市京师杜甫高级中学",
						"value": "巩义市京师杜甫高级中学"
					},
					{
						"label": "巩义市第二高级中学",
						"value": "巩义市第二高级中学"
					},
					{
						"label": "巩义市第三高级中学",
						"value": "巩义市第三高级中学"
					},
					{
						"label": "巩义市第四高级中学",
						"value": "巩义市第四高级中学"
					},
					{
						"label": "巩义市市直高级中学",
						"value": "巩义市市直高级中学"
					},
					{
						"label": "河南省巩义中学",
						"value": "河南省巩义中学"
					},
					{
						"label": "巩义市第一高级中学",
						"value": "巩义市第一高级中学"
					}
				]
			}
		]
	},
	{
		"value": "安阳",
		"label": "安阳",
		"children": [
			{
				"label": "北关区",
				"value": "北关区",
				"children": [
					{
						"label": "安阳市北关区启明学校",
						"value": "安阳市北关区启明学校"
					},
					{
						"label": "安阳市第三中学",
						"value": "安阳市第三中学"
					},
					{
						"label": "安阳市第三十九中学",
						"value": "安阳市第三十九中学"
					}
				]
			},
			{
				"label": "汤阴县",
				"value": "汤阴县",
				"children": [
					{
						"label": "汤阴县修远学校",
						"value": "汤阴县修远学校"
					},
					{
						"label": "汤阴县光华学校",
						"value": "汤阴县光华学校"
					},
					{
						"label": "汤阴县育才学校",
						"value": "汤阴县育才学校"
					},
					{
						"label": "汤阴县文源高级中学",
						"value": "汤阴县文源高级中学"
					},
					{
						"label": "汤阴县五一中学",
						"value": "汤阴县五一中学"
					},
					{
						"label": "汤阴县第一中学",
						"value": "汤阴县第一中学"
					}
				]
			},
			{
				"label": "内黄县",
				"value": "内黄县",
				"children": [
					{
						"label": "内黄县第四高级中学",
						"value": "内黄县第四高级中学"
					},
					{
						"label": "内黄县第三中学",
						"value": "内黄县第三中学"
					},
					{
						"label": "内黄县第二高级中学",
						"value": "内黄县第二高级中学"
					},
					{
						"label": "内黄县第一中学",
						"value": "内黄县第一中学"
					},
					{
						"label": "内黄县一中分校",
						"value": "内黄县一中分校"
					},
					{
						"label": "内黄县第一实验高中",
						"value": "内黄县第一实验高中"
					}
				]
			},
			{
				"label": "滑县",
				"value": "滑县",
				"children": [
					{
						"label": "安阳市文心高级中学",
						"value": "安阳市文心高级中学"
					},
					{
						"label": "滑县豫北高级中学",
						"value": "滑县豫北高级中学"
					},
					{
						"label": "河南省滑县第二高级中学",
						"value": "河南省滑县第二高级中学"
					},
					{
						"label": "滑县第六高级中学",
						"value": "滑县第六高级中学"
					},
					{
						"label": "滑县第三高级中学",
						"value": "滑县第三高级中学"
					},
					{
						"label": "滑县第一高级中学",
						"value": "滑县第一高级中学"
					},
					{
						"label": "滑县第四高级中学",
						"value": "滑县第四高级中学"
					},
					{
						"label": "安阳市第二实验中学",
						"value": "安阳市第二实验中学"
					},
					{
						"label": "滑县明德高级中学",
						"value": "滑县明德高级中学"
					},
					{
						"label": "滑县实验学校",
						"value": "滑县实验学校"
					},
					{
						"label": "滑县裳华高级中学",
						"value": "滑县裳华高级中学"
					}
				]
			},
			{
				"label": "殷都区",
				"value": "殷都区",
				"children": [
					{
						"label": "安阳市第二中学",
						"value": "安阳市第二中学"
					},
					{
						"label": "安阳市洹北中学",
						"value": "安阳市洹北中学"
					},
					{
						"label": "安阳市第三十六中学",
						"value": "安阳市第三十六中学"
					},
					{
						"label": "安阳市殷都高级中学",
						"value": "安阳市殷都高级中学"
					},
					{
						"label": "安阳市殷都区翰林高级中学有限公司",
						"value": "安阳市殷都区翰林高级中学有限公司"
					}
				]
			},
			{
				"label": "文峰区",
				"value": "文峰区",
				"children": [
					{
						"label": "安阳市开发区高级中学",
						"value": "安阳市开发区高级中学"
					},
					{
						"label": "安阳市第一中学",
						"value": "安阳市第一中学"
					},
					{
						"label": "安阳正一中学",
						"value": "安阳正一中学"
					},
					{
						"label": "安阳市深蓝高级中学",
						"value": "安阳市深蓝高级中学"
					},
					{
						"label": "安阳示范区飞翔中学",
						"value": "安阳示范区飞翔中学"
					},
					{
						"label": "安阳市文源高中",
						"value": "安阳市文源高中"
					}
				]
			},
			{
				"label": "龙安区",
				"value": "龙安区",
				"children": [
					{
						"label": "安阳市龙安高级中学",
						"value": "安阳市龙安高级中学"
					}
				]
			},
			{
				"label": "安阳县",
				"value": "安阳县",
				"children": [
					{
						"label": "安阳县第三高级中学",
						"value": "安阳县第三高级中学"
					},
					{
						"label": "安阳县第二高级中学",
						"value": "安阳县第二高级中学"
					},
					{
						"label": "安阳县实验中学",
						"value": "安阳县实验中学"
					},
					{
						"label": "安阳县第七高级中学",
						"value": "安阳县第七高级中学"
					},
					{
						"label": "安阳县彰德实验中学",
						"value": "安阳县彰德实验中学"
					},
					{
						"label": "安阳县水冶镇育才实验高中",
						"value": "安阳县水冶镇育才实验高中"
					},
					{
						"label": "安阳县聚贤高级中学",
						"value": "安阳县聚贤高级中学"
					},
					{
						"label": "安阳县新坐标高级中学",
						"value": "安阳县新坐标高级中学"
					},
					{
						"label": "安阳县第一高级中学",
						"value": "安阳县第一高级中学"
					}
				]
			},
			{
				"label": "林州市",
				"value": "林州市",
				"children": [
					{
						"label": "林州市实验中学",
						"value": "林州市实验中学"
					},
					{
						"label": "林州市硕丰学校",
						"value": "林州市硕丰学校"
					},
					{
						"label": "林州市第四中学",
						"value": "林州市第四中学"
					},
					{
						"label": "林州市第三中学",
						"value": "林州市第三中学"
					},
					{
						"label": "林州市第二高级中学",
						"value": "林州市第二高级中学"
					},
					{
						"label": "林州市第一中学",
						"value": "林州市第一中学"
					},
					{
						"label": "林州市四新中学",
						"value": "林州市四新中学"
					},
					{
						"label": "林州市世纪学校",
						"value": "林州市世纪学校"
					},
					{
						"label": "林州市林虑中学",
						"value": "林州市林虑中学"
					}
				]
			}
		]
	},
	{
		"value": "平顶山",
		"label": "平顶山",
		"children": [
			{
				"label": "叶县",
				"value": "叶县",
				"children": [
					{
						"label": "叶县晨德学校",
						"value": "叶县晨德学校"
					},
					{
						"label": "叶县高级中学",
						"value": "叶县高级中学"
					},
					{
						"label": "叶县第二高级中学",
						"value": "叶县第二高级中学"
					},
					{
						"label": "叶县融通实验高级中学",
						"value": "叶县融通实验高级中学"
					},
					{
						"label": "叶县旧县高级中学",
						"value": "叶县旧县高级中学"
					},
					{
						"label": "叶县博文学校",
						"value": "叶县博文学校"
					}
				]
			},
			{
				"label": "汝州市",
				"value": "汝州市",
				"children": [
					{
						"label": "汝州市实验中学",
						"value": "汝州市实验中学"
					},
					{
						"label": "汝州市万基高级中学",
						"value": "汝州市万基高级中学"
					},
					{
						"label": "汝州市敬之中学",
						"value": "汝州市敬之中学"
					},
					{
						"label": "汝州外国语学校（高中部）",
						"value": "汝州外国语学校（高中部）"
					},
					{
						"label": "汝州市第五高级中学",
						"value": "汝州市第五高级中学"
					},
					{
						"label": "汝州市第四高级中学",
						"value": "汝州市第四高级中学"
					},
					{
						"label": "汝州市第一高级中学",
						"value": "汝州市第一高级中学"
					},
					{
						"label": "汝州市第二高级中学",
						"value": "汝州市第二高级中学"
					},
					{
						"label": "汝州市第三高级中学",
						"value": "汝州市第三高级中学"
					}
				]
			},
			{
				"label": "鲁山县",
				"value": "鲁山县",
				"children": [
					{
						"label": "鲁山县江河高级中学",
						"value": "鲁山县江河高级中学"
					},
					{
						"label": "鲁山县兴源高级中学",
						"value": "鲁山县兴源高级中学"
					},
					{
						"label": "河南省鲁山县第二高级中学",
						"value": "河南省鲁山县第二高级中学"
					},
					{
						"label": "河南省鲁山县第一高级中学",
						"value": "河南省鲁山县第一高级中学"
					},
					{
						"label": "河南省鲁山县第三高级中学",
						"value": "河南省鲁山县第三高级中学"
					}
				]
			},
			{
				"label": "舞钢市",
				"value": "舞钢市",
				"children": [
					{
						"label": "舞钢市第二高级中学",
						"value": "舞钢市第二高级中学"
					},
					{
						"label": "舞钢市第一高级中学",
						"value": "舞钢市第一高级中学"
					},
					{
						"label": "舞钢市新时代国际学校",
						"value": "舞钢市新时代国际学校"
					},
					{
						"label": "舞钢市实验高级中学",
						"value": "舞钢市实验高级中学"
					}
				]
			},
			{
				"label": "郏县",
				"value": "郏县",
				"children": [
					{
						"label": "郏县融通实验高级中学",
						"value": "郏县融通实验高级中学"
					},
					{
						"label": "郏县第二高级中学",
						"value": "郏县第二高级中学"
					},
					{
						"label": "郏县第一高级中学",
						"value": "郏县第一高级中学"
					}
				]
			},
			{
				"label": "石龙区",
				"value": "石龙区",
				"children": [
					{
						"label": "平顶山市龙河实验高级中学",
						"value": "平顶山市龙河实验高级中学"
					},
					{
						"label": "平顶山市石龙区高级中学",
						"value": "平顶山市石龙区高级中学"
					}
				]
			},
			{
				"label": "卫东区",
				"value": "卫东区",
				"children": [
					{
						"label": "平顶山市蓝天高级中学",
						"value": "平顶山市蓝天高级中学"
					},
					{
						"label": "平顶山市第一高级中学",
						"value": "平顶山市第一高级中学"
					},
					{
						"label": "平顶山市第三高级中学",
						"value": "平顶山市第三高级中学"
					}
				]
			},
			{
				"label": "宝丰县",
				"value": "宝丰县",
				"children": [
					{
						"label": "宝丰县第一高级中学",
						"value": "宝丰县第一高级中学"
					},
					{
						"label": "宝丰县第二高级中学",
						"value": "宝丰县第二高级中学"
					},
					{
						"label": "宝丰县红星高级中学",
						"value": "宝丰县红星高级中学"
					},
					{
						"label": "平顶山衡水卓越高级中学",
						"value": "平顶山衡水卓越高级中学"
					}
				]
			},
			{
				"label": "湛河区",
				"value": "湛河区",
				"children": [
					{
						"label": "平顶山市第一中学",
						"value": "平顶山市第一中学"
					},
					{
						"label": "平顶山市第二中学",
						"value": "平顶山市第二中学"
					}
				]
			},
			{
				"label": "新华区",
				"value": "新华区",
				"children": [
					{
						"label": "平顶山市第八中学",
						"value": "平顶山市第八中学"
					},
					{
						"label": "平顶山黄河外国语学校",
						"value": "平顶山黄河外国语学校"
					},
					{
						"label": "平顶山市第二高级中学",
						"value": "平顶山市第二高级中学"
					},
					{
						"label": "平顶山市实验高中",
						"value": "平顶山市实验高中"
					}
				]
			},
			{
				"label": "新城区",
				"value": "新城区",
				"children": [
					{
						"label": "平顶山一中新区学校",
						"value": "平顶山一中新区学校"
					}
				]
			}
		]
	},
	{
		"value": "鹤壁",
		"label": "鹤壁",
		"children": [
			{
				"label": "浚县",
				"value": "浚县",
				"children": [
					{
						"label": "浚县科达中学",
						"value": "浚县科达中学"
					},
					{
						"label": "浚县第三高级中学",
						"value": "浚县第三高级中学"
					},
					{
						"label": "浚县第二高级中学",
						"value": "浚县第二高级中学"
					},
					{
						"label": "浚县第一中学",
						"value": "浚县第一中学"
					},
					{
						"label": "鹤壁黎阳中学",
						"value": "鹤壁黎阳中学"
					}
				]
			},
			{
				"label": "淇滨区",
				"value": "淇滨区",
				"children": [
					{
						"label": "鹤壁科达学校",
						"value": "鹤壁科达学校"
					},
					{
						"label": "淇滨高级中学",
						"value": "淇滨高级中学"
					},
					{
						"label": "鹤壁市高中",
						"value": "鹤壁市高中"
					},
					{
						"label": "鹤壁市外国语中学",
						"value": "鹤壁市外国语中学"
					},
					{
						"label": "鹤壁北大培文中学",
						"value": "鹤壁北大培文中学"
					}
				]
			},
			{
				"label": "山城区",
				"value": "山城区",
				"children": [
					{
						"label": "鹤壁市第一中学",
						"value": "鹤壁市第一中学"
					},
					{
						"label": "山城区综合高中",
						"value": "山城区综合高中"
					}
				]
			},
			{
				"label": "淇县",
				"value": "淇县",
				"children": [
					{
						"label": "鹤壁市育才学校",
						"value": "鹤壁市育才学校"
					},
					{
						"label": "鹤壁市鹤高中学",
						"value": "鹤壁市鹤高中学"
					},
					{
						"label": "淇县第一中学",
						"value": "淇县第一中学"
					}
				]
			},
			{
				"label": "鹤山区",
				"value": "鹤山区",
				"children": [
					{
						"label": "鹤壁市鹤山区高级中学",
						"value": "鹤壁市鹤山区高级中学"
					}
				]
			}
		]
	},
	{
		"value": "开封",
		"label": "开封",
		"children": [
			{
				"label": "尉氏县",
				"value": "尉氏县",
				"children": [
					{
						"label": "开封市第二实验高级中学",
						"value": "开封市第二实验高级中学"
					},
					{
						"label": "尉氏县尉州高级中学",
						"value": "尉氏县尉州高级中学"
					},
					{
						"label": "尉氏县民开学校",
						"value": "尉氏县民开学校"
					},
					{
						"label": "尉氏县第三高级中学",
						"value": "尉氏县第三高级中学"
					},
					{
						"label": "尉氏县第三高级中学北校区",
						"value": "尉氏县第三高级中学北校区"
					},
					{
						"label": "尉氏县第三高级中学洧川校区",
						"value": "尉氏县第三高级中学洧川校区"
					}
				]
			},
			{
				"label": "龙亭区",
				"value": "龙亭区",
				"children": [
					{
						"label": "河南大学附属中学",
						"value": "河南大学附属中学"
					},
					{
						"label": "开封市河大附中实验学校",
						"value": "开封市河大附中实验学校"
					},
					{
						"label": "开封市开封高中实验学校",
						"value": "开封市开封高中实验学校"
					},
					{
						"label": "开封市朱仙镇立洋外国语学校",
						"value": "开封市朱仙镇立洋外国语学校"
					},
					{
						"label": "开封市田家炳实验中学",
						"value": "开封市田家炳实验中学"
					},
					{
						"label": "开封市第二十五中学",
						"value": "开封市第二十五中学"
					},
					{
						"label": "开封市立洋外国语学校",
						"value": "开封市立洋外国语学校"
					}
				]
			},
			{
				"label": "通许县",
				"value": "通许县",
				"children": [
					{
						"label": "通许县第三高级中学",
						"value": "通许县第三高级中学"
					},
					{
						"label": "通许县启智高中",
						"value": "通许县启智高中"
					},
					{
						"label": "通许县第一高级中学",
						"value": "通许县第一高级中学"
					},
					{
						"label": "通许县实验中学",
						"value": "通许县实验中学"
					},
					{
						"label": "通许县扬坤高级中学",
						"value": "通许县扬坤高级中学"
					},
					{
						"label": "通许县丽星高级中学",
						"value": "通许县丽星高级中学"
					}
				]
			},
			{
				"label": "祥符区",
				"value": "祥符区",
				"children": [
					{
						"label": "开封市祥符区第一高级中学实验学校",
						"value": "开封市祥符区第一高级中学实验学校"
					},
					{
						"label": "开封市祥符区第四高级中学",
						"value": "开封市祥符区第四高级中学"
					},
					{
						"label": "开封市祥符区华洋外国语学校",
						"value": "开封市祥符区华洋外国语学校"
					},
					{
						"label": "开封市祥符区宇华实验中学",
						"value": "开封市祥符区宇华实验中学"
					},
					{
						"label": "开封黎明中学",
						"value": "开封黎明中学"
					},
					{
						"label": "河南省开封市祥符区高级中学",
						"value": "河南省开封市祥符区高级中学"
					},
					{
						"label": "开封市祥符区第二高级中学",
						"value": "开封市祥符区第二高级中学"
					}
				]
			},
			{
				"label": "兰考县",
				"value": "兰考县",
				"children": [
					{
						"label": "兰考县新亚高级中学",
						"value": "兰考县新亚高级中学"
					},
					{
						"label": "兰考县第一高级中学",
						"value": "兰考县第一高级中学"
					},
					{
						"label": "兰考县第三高级中学",
						"value": "兰考县第三高级中学"
					},
					{
						"label": "兰考县第二高级中学",
						"value": "兰考县第二高级中学"
					},
					{
						"label": "兰考县创新中学",
						"value": "兰考县创新中学"
					}
				]
			},
			{
				"label": "杞县",
				"value": "杞县",
				"children": [
					{
						"label": "杞县金杞高级中学",
						"value": "杞县金杞高级中学"
					},
					{
						"label": "杞县阳光立洋外国语学校",
						"value": "杞县阳光立洋外国语学校"
					},
					{
						"label": "杞县希望学校高中部",
						"value": "杞县希望学校高中部"
					},
					{
						"label": "杞县大同高级中学",
						"value": "杞县大同高级中学"
					},
					{
						"label": "河南省杞县高中",
						"value": "河南省杞县高中"
					},
					{
						"label": "杞县实验高中",
						"value": "杞县实验高中"
					},
					{
						"label": "杞县第二高级中学",
						"value": "杞县第二高级中学"
					},
					{
						"label": "杞县第一高级中学",
						"value": "杞县第一高级中学"
					}
				]
			},
			{
				"label": "鼓楼区",
				"value": "鼓楼区",
				"children": [
					{
						"label": "开封市兴华中学",
						"value": "开封市兴华中学"
					},
					{
						"label": "开封市第五中学",
						"value": "开封市第五中学"
					}
				]
			},
			{
				"label": "顺河回族区",
				"value": "顺河回族区",
				"children": [
					{
						"label": "开封北大培文东信学校",
						"value": "开封北大培文东信学校"
					},
					{
						"label": "开封市第十中学",
						"value": "开封市第十中学"
					},
					{
						"label": "开封市回民中学",
						"value": "开封市回民中学"
					},
					{
						"label": "开封市求实高级中学",
						"value": "开封市求实高级中学"
					},
					{
						"label": "开封市第七中学",
						"value": "开封市第七中学"
					}
				]
			},
			{
				"label": "北郊乡",
				"value": "北郊乡",
				"children": [
					{
						"label": "开封市第十七中学",
						"value": "开封市第十七中学"
					}
				]
			},
			{
				"label": "禹王台区",
				"value": "禹王台区",
				"children": [
					{
						"label": "开封市铁路中学",
						"value": "开封市铁路中学"
					},
					{
						"label": "河南省开封高级中学",
						"value": "河南省开封高级中学"
					},
					{
						"label": "开封市新世纪高级中学",
						"value": "开封市新世纪高级中学"
					}
				]
			}
		]
	},
	{
		"value": "三门峡",
		"label": "三门峡",
		"children": [
			{
				"label": "陕州区",
				"value": "陕州区",
				"children": [
					{
						"label": "三门峡市陕州区第二高级中学",
						"value": "三门峡市陕州区第二高级中学"
					},
					{
						"label": "三门峡市陕州区第一高级中学",
						"value": "三门峡市陕州区第一高级中学"
					},
					{
						"label": "陕州中学",
						"value": "陕州中学"
					}
				]
			},
			{
				"label": "卢氏县",
				"value": "卢氏县",
				"children": [
					{
						"label": "卢氏县第一高级中学",
						"value": "卢氏县第一高级中学"
					},
					{
						"label": "卢氏县实验高级中学",
						"value": "卢氏县实验高级中学"
					}
				]
			},
			{
				"label": "渑池县",
				"value": "渑池县",
				"children": [
					{
						"label": "渑池县第二高级中学",
						"value": "渑池县第二高级中学"
					},
					{
						"label": "河南省渑池高级中学",
						"value": "河南省渑池高级中学"
					}
				]
			},
			{
				"label": "湖滨区",
				"value": "湖滨区",
				"children": [
					{
						"label": "三门峡万极外国语高级中学",
						"value": "三门峡万极外国语高级中学"
					},
					{
						"label": "三门峡市第一高级中学",
						"value": "三门峡市第一高级中学"
					},
					{
						"label": "三门峡市实验高中",
						"value": "三门峡市实验高中"
					},
					{
						"label": "三门峡市外国语高级中学",
						"value": "三门峡市外国语高级中学"
					},
					{
						"label": "三门峡市第三高级中学",
						"value": "三门峡市第三高级中学"
					},
					{
						"label": "三门峡黄冈实验学校",
						"value": "三门峡黄冈实验学校"
					}
				]
			},
			{
				"label": "灵宝市",
				"value": "灵宝市",
				"children": [
					{
						"label": "三门峡高新一中",
						"value": "三门峡高新一中"
					},
					{
						"label": "灵宝市实验高级中学",
						"value": "灵宝市实验高级中学"
					},
					{
						"label": "灵宝市第一高级中学",
						"value": "灵宝市第一高级中学"
					},
					{
						"label": "灵宝市第二高级中学",
						"value": "灵宝市第二高级中学"
					},
					{
						"label": "灵宝市第三高级中学",
						"value": "灵宝市第三高级中学"
					},
					{
						"label": "灵宝市第五高级中学",
						"value": "灵宝市第五高级中学"
					}
				]
			},
			{
				"label": "义马市",
				"value": "义马市",
				"children": [
					{
						"label": "义马市高级中学",
						"value": "义马市高级中学"
					}
				]
			}
		]
	},
	{
		"value": "焦作",
		"label": "焦作",
		"children": [
			{
				"label": "山阳区",
				"value": "山阳区",
				"children": [
					{
						"label": "焦作希望实验学校",
						"value": "焦作希望实验学校"
					},
					{
						"label": "焦作市第一中学",
						"value": "焦作市第一中学"
					},
					{
						"label": "焦作太行中学",
						"value": "焦作太行中学"
					},
					{
						"label": "焦作市文昌中学",
						"value": "焦作市文昌中学"
					},
					{
						"label": "焦作市第十二中学",
						"value": "焦作市第十二中学"
					},
					{
						"label": "焦作市宇华实验学校",
						"value": "焦作市宇华实验学校"
					}
				]
			},
			{
				"label": "博爱县",
				"value": "博爱县",
				"children": [
					{
						"label": "博爱县英才学校",
						"value": "博爱县英才学校"
					},
					{
						"label": "博爱县第二中学",
						"value": "博爱县第二中学"
					},
					{
						"label": "博爱县第一中学",
						"value": "博爱县第一中学"
					},
					{
						"label": "博爱县高级中学",
						"value": "博爱县高级中学"
					}
				]
			},
			{
				"label": "孟州市",
				"value": "孟州市",
				"children": [
					{
						"label": "孟州市第五高级中学",
						"value": "孟州市第五高级中学"
					},
					{
						"label": "孟州市第一高级中学",
						"value": "孟州市第一高级中学"
					}
				]
			},
			{
				"label": "沁阳市",
				"value": "沁阳市",
				"children": [
					{
						"label": "沁阳市覃怀中学",
						"value": "沁阳市覃怀中学"
					},
					{
						"label": "沁阳市高级中学",
						"value": "沁阳市高级中学"
					},
					{
						"label": "沁阳市第一中学",
						"value": "沁阳市第一中学"
					},
					{
						"label": "沁阳市永威学校",
						"value": "沁阳市永威学校"
					}
				]
			},
			{
				"label": "解放区",
				"value": "解放区",
				"children": [
					{
						"label": "焦作市北清中学",
						"value": "焦作市北清中学"
					},
					{
						"label": "焦作市津开中学",
						"value": "焦作市津开中学"
					},
					{
						"label": "焦作市外国语中学",
						"value": "焦作市外国语中学"
					},
					{
						"label": "焦作市第十一中学",
						"value": "焦作市第十一中学"
					},
					{
						"label": "焦作师范高等专科学校附属中学",
						"value": "焦作师范高等专科学校附属中学"
					}
				]
			},
			{
				"label": "马村区",
				"value": "马村区",
				"children": [
					{
						"label": "焦作市明天高级中学",
						"value": "焦作市明天高级中学"
					}
				]
			},
			{
				"label": "武陟县",
				"value": "武陟县",
				"children": [
					{
						"label": "武陟县城关高中",
						"value": "武陟县城关高中"
					},
					{
						"label": "武陟县河朔第一中学",
						"value": "武陟县河朔第一中学"
					},
					{
						"label": "武陟县第一中学",
						"value": "武陟县第一中学"
					},
					{
						"label": "武陟县武陟中学",
						"value": "武陟县武陟中学"
					}
				]
			},
			{
				"label": "修武县",
				"value": "修武县",
				"children": [
					{
						"label": "修武县实验高级中学",
						"value": "修武县实验高级中学"
					},
					{
						"label": "修武县第一中学",
						"value": "修武县第一中学"
					}
				]
			},
			{
				"label": "温县",
				"value": "温县",
				"children": [
					{
						"label": "温县第一高级中学",
						"value": "温县第一高级中学"
					},
					{
						"label": "温县新宇中学",
						"value": "温县新宇中学"
					},
					{
						"label": "温县实验高级中学",
						"value": "温县实验高级中学"
					}
				]
			},
			{
				"label": "临颍县",
				"value": "临颍县",
				"children": [
					{
						"label": "临颍县博雅学校",
						"value": "临颍县博雅学校"
					},
					{
						"label": "临颍县第一高级中学",
						"value": "临颍县第一高级中学"
					},
					{
						"label": "临颍县南街高级中学",
						"value": "临颍县南街高级中学"
					},
					{
						"label": "临颍县第二高级中学",
						"value": "临颍县第二高级中学"
					},
					{
						"label": "临颍县第三高级中学",
						"value": "临颍县第三高级中学"
					}
				]
			},
			{
				"label": "中站区",
				"value": "中站区",
				"children": [
					{
						"label": "河南省焦作市第四中学",
						"value": "河南省焦作市第四中学"
					}
				]
			}
		]
	},
	{
		"value": "济源",
		"label": "济源",
		"children": [
			{
				"label": "济源市",
				"value": "济源市",
				"children": [
					{
						"label": "济源高级中学",
						"value": "济源高级中学"
					},
					{
						"label": "河南省济源第一中学",
						"value": "河南省济源第一中学"
					},
					{
						"label": "济源市第五中学",
						"value": "济源市第五中学"
					},
					{
						"label": "济源市第六中学",
						"value": "济源市第六中学"
					},
					{
						"label": "济源孟国平双语学校",
						"value": "济源孟国平双语学校"
					}
				]
			},
			{
				"label": "愚公路",
				"value": "愚公路",
				"children": [
					{
						"label": "济源高级中学",
						"value": "济源高级中学"
					},
					{
						"label": "济源英才学校",
						"value": "济源英才学校"
					}
				]
			},
			{
				"label": "轵城镇",
				"value": "轵城镇",
				"children": [
					{
						"label": "济源市第四中学",
						"value": "济源市第四中学"
					}
				]
			}
		]
	},
	{
		"value": "新密",
		"label": "新密",
		"children": [
			{
				"label": "新华路",
				"value": "新华路",
				"children": [
					{
						"label": "河南省新密市第三高级中学",
						"value": "河南省新密市第三高级中学"
					}
				]
			}
		]
	}
]